import { clear } from "@testing-library/user-event/dist/clear";
import React, { useEffect, useState } from "react";
import axios from "axios";
import e from "cors";
import { useLocation, useNavigate } from "react-router-dom";

const EditUser = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isAnalyst, setIsAnalyst] = useState(false);
  const [hasReportingAccess, setHasReportingAccess] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [customerCode, setCustomerCode] = useState("");
  const [isAccountActivated, setIsAccountActivated] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [accountType, setAccountType] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate(); // Instantiate useHistory hook

  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = () => {
    if (accountType != "") {
      setErrorMessage("");
      //   setIsManager(false);
      //   setIsAdmin(false);
      //   setIsCustomer(false);
      //   setIsAnalyst(false);

      if (accountType == "Analyst") {
        setIsAnalyst(true);
      }
      if (accountType == "Manager") {
        setIsManager(true);
      }
      if (accountType == "Customer") {
        setIsCustomer(true);
      }
      if (accountType == "Administrator") {
        setIsAdmin(true);
      }
      const emailArr = email.split(",");
      const uniqueEmailValues = [
        ...new Set(emailArr.map((value) => value.trim())),
      ];
      let finalEmails = uniqueEmailValues.join(",");
      finalEmails = finalEmails.replace(/\s/g, "");
      const token = window.localStorage.getItem("accessToken");
      let data = JSON.stringify({
        email: email,
        name: name,
        is_analyst: isAnalyst,
        is_manager: isManager,
        has_reporting_access: hasReportingAccess === "Yes" ? true : false,
        is_customer: isCustomer,
        is_account_activated: isAccountActivated === "Yes" ? true : false,
        is_administrator: isAdmin,
        customer_code: customerCode === "NA" ? "" : customerCode,
      });
      let config = {
        method: "put",
        // url: `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/user/${state?.userDetails?.ID}`,
        url: isUserAdmin
          ? `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/user/${state?.userDetails?.ID}`
          : `${process.env.REACT_APP_API_URL}/api/v1/authenticated/user/${state?.userDetails?.ID}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          clearData();
          navigate(`/Manager/manage-team`);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setErrorMessage("Please choose account type.");
    }
  };

  const handleAccountType = (e) => {
    setAccountType(e.target.value);
    if (e.target.value === "Administrator") {
      setIsAdmin(true);
      setIsCustomer(false);
      setIsAnalyst(true);
      setIsManager(true);
    } else if (e.target.value === "Manager") {
      setIsAdmin(false);
      setIsCustomer(false);
      setIsAnalyst(true);
      setIsManager(true);
    } else if (e.target.value === "Analyst") {
      setIsAdmin(false);
      setIsCustomer(false);
      setIsAnalyst(true);
      setIsManager(false);
    } else if (e.target.value === "Customer") {
      setIsAdmin(false);
      setIsCustomer(true);
      setIsAnalyst(false);
      setIsManager(false);
    }
  };
  const handleEmailInput = (e) => {
    const inputValue = e.target.value;
    const inputValues = inputValue.split(",");

    if (inputValues.length > 10) {
      alert("You can't enter more than 10 emails.");
    } else {
      setEmail(inputValue);
    }
  };

  function clearData(params) {
    setEmail("");
    setIsAnalyst(false);
    setIsCustomer(false);
    setIsAdmin(false);
    setIsAccountActivated(false);
    setIsManager(false);
    setCustomerCode("");
    setHasReportingAccess(false);
    setAccountType("");
  }
  useEffect(() => {
    setName(state?.userDetails?.Name);
    setEmail(
      [
        state?.userDetails?.Email,
        state?.userDetails?.Email2,
        state?.userDetails?.Email3,
        state?.userDetails?.Email4,
        state?.userDetails?.Email5,
        state?.userDetails?.Email6,
        state?.userDetails?.Email7,
        state?.userDetails?.Email8,
        state?.userDetails?.Email9,
        state?.userDetails?.Email10,
      ]
        .filter((email) => email !== "") // Filter out empty strings
        .join(", ")
    );
    setIsAnalyst(state?.userDetails?.IsAnalyst);
    setIsCustomer(state?.userDetails?.IsCustomer);
    setIsAdmin(state?.userDetails?.IsAdministrator);
    setIsAccountActivated(
      state?.userDetails?.IsAccountActivated ? "Yes" : "No"
    );
    setIsManager(state?.userDetails?.IsManager);
    setCustomerCode(
      state?.userDetails?.CustomerCode === ""
        ? "NA"
        : state?.userDetails?.CustomerCode
    );
    setHasReportingAccess(
      state?.userDetails?.HasReportingAccess ? "Yes" : "No"
    );
    setAccountType(
      state?.userDetails?.IsAdministrator
        ? "Administrator"
        : state?.userDetails?.IsManager
        ? "Manager"
        : state?.userDetails?.IsAnalyst
        ? "Analyst"
        : "Customer"
    );
  }, []);

  useEffect(() => {
    try {
      const token = window.localStorage.getItem("accessToken");

      axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/authenticated/me`, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          setIsUserAdmin(response?.data?.IsAdministrator);
        })
        .catch(function (err) {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <section className="card-form">
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <div className="card card-log-head my-3">
              <h4 className="card-header text-center card-log">Edit User</h4>
              <div className="card-body card-log-body">
                <br />

                <p className="log-form-head">Name:</p>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  type="text"
                />
                <br />
                <p className="log-form-head">Email:</p>
                <input
                  value={email}
                  onChange={handleEmailInput}
                  className="form-control"
                  type="text"
                />
                <br />

                <p className="log-form-head">Account type:</p>
                <select
                  value={accountType}
                  onChange={handleAccountType}
                  className="form-control"
                >
                  <option value={""}>Select account type</option>
                  <option value={"Analyst"}>Analyst</option>
                  <option value={"Manager"}>Manager</option>
                  <option value={"Customer"}>Customer</option>
                  <option value={"Administrator"}>Administrator</option>
                </select>
                <br />

                {/* <p className="log-form-head">Is Analyst:</p>
                                <select onChange={(e) => setIsAnalyst(e.target.value === "true")} className="form-control">
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                                <br /> */}
                {/* <p className="log-form-head">Is Manager:</p>
                                <select onChange={(e) => setIsManager(e.target.value === "true")} className="form-control">
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                                <br /> */}
                <p className="log-form-head">Has Reporting Access:</p>
                <select
                  value={hasReportingAccess}
                  onChange={(e) => setHasReportingAccess(e.target.value)}
                  className="form-control"
                >
                  <option value={"No"}>No</option>
                  <option value={"Yes"}>Yes</option>
                </select>
                <br />
                {/* <p className="log-form-head">Is Customer:</p>
                                <select onChange={(e) => setIsCustomer(e.target.value === "true")} className="form-control">
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                                <br /> */}
                <p className="log-form-head">Is Account Activated:</p>
                <select
                  value={isAccountActivated}
                  onChange={(e) => setIsAccountActivated(e.target.value)}
                  className="form-control"
                >
                  <option value={"No"}>No</option>
                  <option value={"Yes"}>Yes</option>
                </select>
                <br />
                {/* <p className="log-form-head">Is Administrator:</p>
                                <select onChange={(e) => setIsAdmin(e.target.value === "true")} className="form-control">
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                                <br /> */}
                {/* <p className="log-form-head">Customer Code:</p>
                                <input value={customerCode!==""?customerCode:"NA"} onChange={(e) => setCustomerCode(e.target.value)} className="form-control" type="text" />
                                <br /> */}
                <br />
                <p>{errorMessage}</p>
                <button
                  onClick={onSubmit}
                  className="btn btn-primary mt-2 log-form-submit"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditUser;
