import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "../../partials/Alert";

// EventForm component that displays the form and handles the data submission.
const EventForm = () => {
  const [displayAlert, setDisplayAlert] = useState("none");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType] = useState("danger");
  const [event, setEvent] = useState("");
  const [impact, setImpact] = useState("");
  const [sourceIP, setSourceIP] = useState("");
  const [sourcePort, setSourcePort] = useState("");
  const [destinationIP, setDestinationIP] = useState("");
  const [destinationPort, setDestinationPort] = useState("");
  const [customerName, setCustomerName] = useState("viperlineoffice");
  const [analystName, setAnalystName] = useState("");
  const [eventSeverity, setEventSeverity] = useState(1);
  const [resolved, setResolved] = useState(1);
  const [timestamp, setTimestamp] = useState(
    new Date().toISOString().substring(0, 16)
  );
  const [timestampend, setTimestampEnd] = useState(
    new Date().toISOString().substring(0, 16)
  );
  const [showTimeStampEnd, setShowTimeStampEnd] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);

  const navigate = useNavigate(); // Instantiate useHistory hook

  const [customers, setCustomers] = useState([]);

  // handleFormSubmit function that handles the form submission.
  const handleFormSubmit = async () => {
    const token = window.localStorage.getItem("accessToken");
    // Event object
    const eventData = {
      event,
      impact,
      sourceIP,
      sourcePort: sourcePort.toString(),
      destinationIP,
      destinationPort: destinationPort.toString(),
      sendNotification: sendNotification? 1: 0,
      customerName,
      analystName: window.localStorage.getItem("analyst_name"),
      timestamp: timestamp.replace("T", " ").replace("Z") + ":00",
      timestampend:
        showTimeStampEnd === true
          ? timestampend.replace("T", " ").replace("Z", "") + ":00"
          : "2006-01-01 00:00:00",
      eventSeverity: eventSeverity,
      resolved: resolved,
    };

    if (token) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/v1/authenticated/events`,
          eventData,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          // You can add further actions to be performed on success, like clearing the form, showing a success message, etc.
          navigate("/Analyst/Index");
        })
        .catch(function (error) {
          console.log(error);
          // You can add further actions to be performed on error, like showing an error message, etc.
          setDisplayAlert("block");
          setAlertMsg(
            "The event could not be saved. Check that all fields have been filled out and try again!"
          );
        });
    }
  };

  const fetchCustomers = async () => {
    const token = window.localStorage.getItem("accessToken");
    // Event object

    if (token) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/authenticated/all-user-listing`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          const customerArr = response?.data?.data.filter((customer) => {
            return customer.IsCustomer;
          });
          setCustomers(customerArr);
        })
        .catch(function (error) {
          console.log(error);
          // You can add further actions to be performed on error, like showing an error message, etc.
          setDisplayAlert("block");
          setAlertMsg(
            "The event could not be saved. Check that all fields have been filled out and try again!"
          );
        });
    }
  };

  // useEffect(() => {
  //     fetchCustomers()
  // }, [])

  const handleOnChange = (e) => {
    setShowTimeStampEnd(!showTimeStampEnd);
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <section className="card-form">
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <div className="card card-log-head my-3">
              <Alert
                type={alertType}
                message={alertMsg}
                show={displayAlert}
                close={() => setDisplayAlert("none")}
              />

              <h4 className="card-header text-center card-log">Log Alert</h4>
              <div className="card-body card-log-body">
                <p className="card-log-body text-center">
                  Use this form to log an alert into the system.
                </p>
                <br />

                <p className="log-form-head">Event:</p>
                <input
                  className="form-control"
                  type="text"
                  value={event}
                  onChange={(e) => setEvent(e.target.value)}
                />
                <br />
                <p className="log-form-head">Impact:</p>
                <textarea
                  className="form-control"
                  value={impact}
                  onChange={(e) => setImpact(e.target.value)}
                />
                <br />
                <p className="log-form-head">Source IP:</p>
                <input
                  className="form-control"
                  type="text"
                  value={sourceIP}
                  onChange={(e) => setSourceIP(e.target.value)}
                />
                <br />
                <p className="log-form-head">Source Port:</p>
                <input
                  className="form-control"
                  type="text"
                  value={sourcePort}
                  onChange={(e) => setSourcePort(e.target.value)}
                />
                <br />
                <p className="log-form-head">Destination IP:</p>
                <input
                  className="form-control"
                  type="text"
                  value={destinationIP}
                  onChange={(e) => setDestinationIP(e.target.value)}
                />
                <br />
                <p className="log-form-head">Destination Port:</p>
                <input
                  className="form-control"
                  type="text"
                  value={destinationPort}
                  onChange={(e) => setDestinationPort(e.target.value)}
                />
                <br />
                <label
                style={{color:"black"}}
                >Do you want to send notifications?</label>
                                <br />

                <input
                style={{marginLeft:"5px", marginTop:"10px", height:"20px", width:"20px"}}
                type="checkbox"
                checked={sendNotification}
                onChange={()=>setSendNotification(!sendNotification)}
                />
                <br />

                <p className="log-form-head">Customer Name:</p>
                <select
                  className="form-control"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                >
                  {/* <option value="viperlineoffice">Viperline Office</option>
                  <option value="tcss">Tuscaloosa County School System</option>
                  <option value="mps">Montgomery Public Schools</option>
                  <option value="bhm">Birmingham City Schools</option>
                  <option value="sev">Sevierville</option>
                  <option value="lee">Lee University</option>
                  <option value="cle">Cleveland</option>
                  <option value="afc">AFC</option>
                  <option value="scac">SCAC</option> */}
                  {customers.map((customer) => {
                    return (
                      <option value={customer.Name}>{customer.Name}</option>
                    );
                  })}
                </select>
                <br />
                <p className="log-form-head">Analyst Name:</p>
                <select
                  className="form-control"
                  value={analystName}
                  onChange={(e) => setAnalystName(e.target.value)}
                >
                  {/* <option value="">Select your name from the dropdown</option> */}
                  <option value={window.localStorage.getItem("analyst_name")}>
                    {window.localStorage.getItem("analyst_name")}
                  </option>
                </select>
                <br />
                <div className="timestamp-head">
                  <div className="timestamp-cols">
                    <p className="log-form-head">Timestamp Start:</p>
                    <div className="">
                      <input
                        className="form-control"
                        type="datetime-local"
                        value={timestamp}
                        onChange={(e) => setTimestamp(e.target.value)}
                      />
                      {/* <input className="form-control" type="datetime-local" value={timestampend} onChange={(e) => setTimestampEnd(e.target.value)} /> */}
                    </div>
                  </div>
                  <div className="timestamp-cols">
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ marginBottom: 10 }}
                        type="checkbox"
                        value={showTimeStampEnd}
                        onChange={handleOnChange}
                      />
                      <p
                        style={{ marginTop: 0, marginLeft: 10 }}
                        className="log-form-head"
                      >
                        Timestamp End:
                      </p>
                    </div>

                    {showTimeStampEnd && (
                      <div className="">
                        <input
                          className="form-control"
                          type="datetime-local"
                          value={timestampend}
                          onChange={(e) => setTimestampEnd(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <p className="log-form-head">Event Severity:</p>
                <select
                  className="form-control"
                  value={eventSeverity}
                  onChange={(e) => setEventSeverity(parseInt(e.target.value))}
                >
                  <option value={3}>Low(3)</option>
                  <option value={2}>Medium(2)</option>
                  <option value={1}>High(1)</option>
                </select>
                <br />
                <button
                  className="btn btn-primary mt-2 log-form-submit"
                  onClick={handleFormSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventForm;
