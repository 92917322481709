import React from 'react';
import styles from "./comment.module.css";
const CommentModal = (props) => {
    const {showCommentModal,setShowCommentModal, comment, setComment,error, setError, handleEditEvent, rowToResolve, setRowToResolve} = props;
    /*
    showCommentModal={showCommentModal}
setShowCommentModal={setShowCommentModal}
comment={comment}
setComment={setComment}
error={error}
setError={setError}
handleCommentModal={handleCommentModal}
    */
    const handleChange = (e)=>{
        setComment(e.target.value)
    }
    const handleClose = ()=>{
        setShowCommentModal(false);
        setRowToResolve(null);
    }
    const handleSubmit =(e)=>{
        e.preventDefault();
        handleEditEvent(rowToResolve);
    }

   return (
    <>
     <div className={styles.overlay}>
        <div className={styles.popup}>
            <div className={styles.modal_text}>
               <button type='button' onClick={handleClose} className={styles.btn_cross}><img src="/images/cross.svg" alt=""/></button>
                    {/* <h3 className="modal-title">Staging.decept10.com says</h3> */}

                
                <form onSubmit={handleSubmit} className={styles.login_form_info}>
                    <label >Please enter your comment</label>
                    <input type="text" value={comment} onChange={handleChange} placeholder="Enter here" className={styles.login_input}/>
                    <p style={{ color: "red" }}>{error}</p>
                    <button type='submit' className={styles.send_btn}>Okay</button>
                    <button type='button' onClick={handleClose} className={styles.cancel_btn}>Cancel</button>
                </form>
            </div>
        </div>
    </div>
    </>
  )
}

export default CommentModal
