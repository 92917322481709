import { clear } from "@testing-library/user-event/dist/clear";
import React, { useEffect, useState } from "react";
import axios from "axios";
import e from "cors";
import { useNavigate } from "react-router-dom";
import ChipInput from "./Components/chip-input";

const CreateUser = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isAnalyst, setIsAnalyst] = useState(false);
  const [hasReportingAccess, setHasReportingAccess] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [customerCode, setCustomerCode] = useState("");
  const [isAccountActivated, setIsAccountActivated] = useState(true);
  const [isCustomer, setIsCustomer] = useState(false);
  const [accountType, setAccountType] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const onSubmit = () => {
    if (accountType != "") {
      // setErrorMessage("");
      // setIsManager(false)
      // setIsAdmin(false)
      // setIsCustomer(false)
      // setIsAnalyst(false)

      // if(accountType == "Analyst") {setIsAnalyst(true)}
      // if(accountType == "Manager") {setIsManager(true)}
      // if(accountType == "Customer") {setIsCustomer(true)}
      // if(accountType == "Administrator") {setIsAdmin(true)}
      const emailArr = email.split(",");
      const uniqueEmailValues = [
        ...new Set(emailArr.map((value) => value.trim())),
      ];
      let finalEmails = uniqueEmailValues.join(",");
      finalEmails = finalEmails.replace(/\s/g, "");
      const token = window.localStorage.getItem("accessToken");
      let data = JSON.stringify({
        email: finalEmails,
        name: name,
        is_analyst: isAnalyst,
        is_manager: isManager,
        has_reporting_access: hasReportingAccess,
        is_customer: isCustomer,
        is_account_activated: isAccountActivated,
        is_administrator: isAdmin,
        customer_code: customerCode,
      });
      let config = {
        method: "post",
        // url: `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/user`,
        url: isUserAdmin
          ? `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/user`
          : `${process.env.REACT_APP_API_URL}/api/v1/authenticated/user`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          clearData();
          navigate("/Manager/manage-team");
        })
        .catch((error) => {
          window.alert(error.response.data.error);
          console.log("catch block", error.response.data.error);
        });
    } else {
      setErrorMessage("Please choose account type.");
    }
  };

  function clearData(params) {
    setEmail("");
    setIsAnalyst(false);
    setIsCustomer(false);
    setIsAdmin(false);
    setIsAccountActivated(true);
    setIsManager(false);
    setCustomerCode("");
    setHasReportingAccess(true);
    setAccountType("");
  }

  const handleSelectChange = (e) => {
    setAccountType(e.target.value);
    if (e.target.value === "Administrator") {
      setIsAdmin(true);
      setIsCustomer(false);
      setIsAnalyst(true);
      setIsManager(true);
    } else if (e.target.value === "Manager") {
      setIsAdmin(false);
      setIsCustomer(false);
      setIsAnalyst(true);
      setIsManager(true);
    } else if (e.target.value === "Analyst") {
      setIsAdmin(false);
      setIsCustomer(false);
      setIsAnalyst(true);
      setIsManager(false);
    } else if (e.target.value === "Customer") {
      setIsAdmin(false);
      setIsCustomer(true);
      setIsAnalyst(false);
      setIsManager(false);
    }
  };

  const handleEmailInput = (e) => {
    const inputValue = e.target.value;
    const inputValues = inputValue.split(/[, ]+/);

    if (inputValues.length > 10) {
      alert("You can't enter more than 10 emails.");
    } else {
      setEmail(inputValue);
    }
  };
  const handelKeyDown = () => {};
  useEffect(() => {
    try {
      const token = window.localStorage.getItem("accessToken");

      axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/authenticated/me`, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          setIsUserAdmin(response?.data?.IsAdministrator);
        })
        .catch(function (err) {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <section className="card-form">
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <div className="card card-log-head my-3">
              <h4 className="card-header text-center card-log">Add User</h4>
              <div className="card-body card-log-body">
                <br />

                <p className="log-form-head">Name:</p>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  type="text"
                />
                <br />
                <p className="log-form-head">Email:</p>
                <input
                  value={email}
                  onChange={handleEmailInput}
                  className="form-control"
                  type="text"
                />
                <br />

                <p className="log-form-head">Account type:</p>
                <select onChange={handleSelectChange} className="form-control">
                  <option value={""}>Select account type</option>
                  <option value={"Analyst"}>Analyst</option>
                  <option value={"Manager"}>Manager</option>
                  <option value={"Customer"}>Customer</option>
                  <option value={"Administrator"}>Administrator</option>
                </select>
                <br />

                {/* <p className="log-form-head">Is Analyst:</p>
                                <select onChange={(e) => setIsAnalyst(e.target.value === "true")} className="form-control">
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                                <br /> */}
                {/* <p className="log-form-head">Is Manager:</p>
                                <select onChange={(e) => setIsManager(e.target.value === "true")} className="form-control">
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                                <br /> */}
                <p className="log-form-head">Has Reporting Access:</p>
                <select
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setHasReportingAccess(true);
                    } else {
                      setHasReportingAccess(false);
                    }
                  }}
                  className="form-control"
                >
                  <option value={"false"}>No</option>
                  <option value={"true"}>Yes</option>
                </select>
                <br />
                {/* <p className="log-form-head">Is Customer:</p>
                                <select onChange={(e) => setIsCustomer(e.target.value === "true")} className="form-control">
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                                <br /> */}
                <p className="log-form-head">Is Account Activated:</p>
                <select
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setIsAccountActivated(true);
                    } else {
                      setIsAccountActivated(false);
                    }
                  }}
                  className="form-control"
                >
                  <option value={"true"}>Yes</option>
                  <option value={"false"}>No</option>
                </select>
                <br />
                {/* <p className="log-form-head">Is Administrator:</p>
                                <select onChange={(e) => setIsAdmin(e.target.value === "true")} className="form-control">
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                                <br /> */}
                {/* <p className="log-form-head">Customer Code:</p>
                                <input value={customerCode} onChange={(e) => setCustomerCode(e.target.value)} className="form-control" type="text" />
                                <br /> */}
                <br />
                <p>{errorMessage}</p>
                <button
                  onClick={onSubmit}
                  className="btn btn-primary mt-2 log-form-submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateUser;
