import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// CSS
import "./scss/app.scss";

// Partials
import Navbar from "./partials/Navbar";
import Footer from "./partials/Footer";
import AuthenticatedPageLoad from "./AuthenticatedPageLoad";
import ReportingAccessPageLoad from "./ReportingAccessPageLoad";
import ManagerAuthenticatedPageLoad from "./ManagerAuthenticatedPageLoad";
import ManageAuthenticatedPages from "./ManageAuthenticatedPages";
import AnalystAuthenticatedPageLoad from "./AnalystAuthenticatedPageLoad";

// Pages
import Home from "./pages/Home";
import CustomerIndex from "./pages/Customer/CustomerIndex";
import EventForm from "./pages/Analyst/EventForm";
import AlertsTable from "./pages/Analyst/AlertTable";
import EditEventForm from "./pages/Analyst/EditEventForm";
import CsvReportForm from "./pages/Reporter/GetReport";
import EventFormBulkUpload from "./pages/Analyst/EventFormBulkUpload";
import CompletePasswordlessLogin from "./pages/Session/CompletePasswordlessLogin";
import PasswordlessLogin from "./pages/Session/PasswordlessLogin";
import UndoCSVImport from "./pages/Admin/UndoCSVImport";
import CreateUser from "./pages/Manager";
import EditUser from "./pages/Manager/edit-user";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navbar />
      <Routes>
        
        <Route
          path="/"
          element={
            <AuthenticatedPageLoad>
              <Home />
            </AuthenticatedPageLoad>
          }
        />
        <Route path="/" element={<PasswordlessLogin />} />
        <Route path="/login" element={<PasswordlessLogin />} />
        
        <Route path="/passwordless" element={<CompletePasswordlessLogin />} />
        <Route
          path="/Analyst/Index"
          element={
            <AnalystAuthenticatedPageLoad>
              <AlertsTable />
            </AnalystAuthenticatedPageLoad>
          }
        />
        <Route
          path="/Analyst/Create"
          element={
            <AnalystAuthenticatedPageLoad>
              <EventForm />
            </AnalystAuthenticatedPageLoad>
          }
        />
        <Route
          path="/Analyst/Edit/:eventId"
          element={
            <AnalystAuthenticatedPageLoad>
              <EditEventForm />
            </AnalystAuthenticatedPageLoad>
          }
        />
        <Route
          path="/Analyst/Import"
          element={
            <AnalystAuthenticatedPageLoad>
              <EventFormBulkUpload />
            </AnalystAuthenticatedPageLoad>
          }
        />
        <Route
          path="/Reporter/Index"
          element={
            <ReportingAccessPageLoad>
              <CsvReportForm />
            </ReportingAccessPageLoad>
          }
        />
        <Route
          path="/Admin/UndoCSVImport"
          element={
            <ManagerAuthenticatedPageLoad>
              <UndoCSVImport />
            </ManagerAuthenticatedPageLoad>
          }
        />
        <Route
          path="/Manager/manage-team"
          element={<ManageAuthenticatedPages></ManageAuthenticatedPages>}
        />
        <Route
          path="/Manager/create-user"
          element={
            <ManagerAuthenticatedPageLoad>
              <CreateUser />
            </ManagerAuthenticatedPageLoad>
          }
        />
        <Route
          path="/Manager/edit-user"
          element={
            <ManagerAuthenticatedPageLoad>
              <EditUser />
            </ManagerAuthenticatedPageLoad>
          }
        />
        <Route
          path="/Customer/Index"
          element={
            <AuthenticatedPageLoad>
              <CustomerIndex />
            </AuthenticatedPageLoad>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);
