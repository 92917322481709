import { useEffect, useState } from "react";
import axios from "axios";
import Alert from "../../partials/Alert";

const GetReport = () => {
  const [displayAlert, setDisplayAlert] = useState("none");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType] = useState("danger");
  const [customerName, setCustomerName] = useState("viperlineoffice");
  const [days, setDays] = useState("7");
  const [allCustomers, setAllCustomners] = useState([]);
  const [loading, setLoading] = useState(true);

  const timeRanges = [
    { label: "1 Day", value: "1" },
    { label: "1 Week", value: "7" },
    { label: "2 Weeks", value: "14" },
    { label: "1 Month", value: "30" },
    { label: "3 Months", value: "90" },
    { label: "6 Months", value: "180" },
    { label: "12 Months", value: "365" },
  ];

  const fetchCustomers = async () => {
    setLoading(true);

    const token = window.localStorage.getItem("accessToken");

    if (token) {
      let url;
      if (localStorage.getItem("isAnalyst") === false) {
        url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/all-user-listing`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/all-user-listing`;
      }
      await axios
        .get(url, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          const customerArr = response?.data?.data?.filter((customer) => {
            return customer?.IsCustomer;
          });

          setAllCustomners(customerArr);
          // Added null checks
          // setPageCount(Math.ceil((response?.data?.total || 0) / pageSize));
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          // Add additional error handling here as needed
          setAlertMsg("Error fetching data. Please try again later.");
          setDisplayAlert("block");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleFormSubmit = async (reportType) => {
    const token = window.localStorage.getItem("accessToken");

    if (token) {
      if (reportType === "word") {
        // Define headers
        const headers = {
          "Content-Type": "application/json",
          "X-Require-Whisk-Auth": "Fm6LaswOC4RDqFR",
        };

        // Define data to send in the POST request
        const data = {
          customer_code: customerName,
          days: days,
          jwt: token,
        };

        // Call the serverless function
        await axios
          .post(
            "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-3f3237e2-a6e6-4814-abca-3cb554db3b3d/report/create",
            data,
            { headers }
          )
          .then(function (response) {
            // Parse URL from response and remove quotes
            const url = response.data.replace(/"/g, "");
            // Create a link to download the report
            const link = document.createElement("a");
            link.href = url;
            let fileName =
              customerName +
              new Date().toISOString().split("T")[0] +
              "_report.docx";
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          })
          .catch(function (error) {
            console.log(error);
            setDisplayAlert("block");
            setAlertMsg(error.message);
          });
      } else {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/authenticated/${reportType}-weekly-report?customerName=${customerName}&days=${days}`,
            {
              headers: {
                authorization: "Bearer " + token,
              },
              responseType: "blob", // important for file download
            }
          )
          .then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            let fileName =
              customerName +
              new Date().toISOString().split("T")[0] +
              "_events." +
              (reportType === "excel" ? "xlsx" : reportType);
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          })
          .catch(function (error) {
            console.log(error);
            setDisplayAlert("block");
            setAlertMsg(error.message);
          });
      }
    }
  };

  return (
    <section className="card-form">
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <div className="card card-log-head my-3">
              <Alert
                type={alertType}
                message={alertMsg}
                show={displayAlert}
                close={() => setDisplayAlert("none")}
              />

              <h4 className="card-header text-center card-log">
                Request Report
              </h4>
              <div className="card-body card-form-body">
                <p className="card-log-body text-center">
                  Use this form to request a report for a customer.
                </p>

                <p className="log-form-head">Customer Name:</p>
                <select
                  className="form-control"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                >
                  {/* <option value="viperlineoffice">Viperline Office</option>
                  <option value="tcss">Tuscaloosa County School System</option>
                  <option value="mps">Montgomery Public Schools</option>
                  <option value="bhm">Birmingham City Schools</option>
                  <option value="sev">Sevierville</option>
                  <option value="lee">Lee University</option>
                  <option value="cle">Cleveland</option>
                  <option value="afc">AFC</option>
                  <option value="scac">SCAC</option> */}
                  {allCustomers?.map((customer) => {
                    return (
                      <option value={customer?.Name}>{customer?.Name}</option>
                    );
                  })}
                </select>
                <br />

                <p className="log-form-head">Time Range:</p>
                <select
                  className="form-control"
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                >
                  {timeRanges.map((range, index) => (
                    <option key={index} value={range.value}>
                      {range.label}
                    </option>
                  ))}
                </select>
                <br />

                <div className="get-report-btn mt-2">
                  <button
                    className="btn btn-primary mt-2 log-form-new-submit me-2"
                    onClick={() => handleFormSubmit("excel")}
                  >
                    Request Excel Report
                  </button>
                  <button
                    className="btn btn-primary mt-2 log-form-new-submit me-2"
                    onClick={() => handleFormSubmit("csv")}
                  >
                    Request CSV Report
                  </button>
                  <button
                    className="btn btn-primary mt-2 log-form-new-submit me-2"
                    onClick={() => handleFormSubmit("word")}
                  >
                    Request Word Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetReport;
