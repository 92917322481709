import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../partials/Alert";
import axios from "axios";
import styles from "./login.module.css";
import { logo2 } from "../../assets/index";

// Login component that displays the login form and handles the login request.
const PasswordlessLogin = () => {
  const [email, setEmail] = useState("");
  const [displayAlert, setDisplayAlert] = useState("none");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType] = useState("success");
  const [error, setError] = useState("");

  // requestLoginLink function that sends the passwordless login link request to the server and handles the response.
  const handleValidation = () => {
    let isValidate = true;
    if (email === "") {
      setError("Please enter email");
      isValidate = false;
    }
    return isValidate;
  };
  const requestLoginLink = () => {
    console.log(process.env.REACT_APP_API_URL);

    if (handleValidation()) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/v1/account/request`, {
          email: email,
        })
        .then(function (response) {
          // Display success alert
          console.log("here...");
          setDisplayAlert("block");
          setAlertMsg(
            "A one-time login link has been sent to your email address. Please close this tab and check your inbox!"
          );
        })
        .catch((err) => {
          // Display error alert
          setDisplayAlert("block");
          setAlertMsg(
            "An error occured while requesting a login link. Please try again! If the problem persists, please contact support."
          );
        });
    }
  };

  return (
    <>
      <Alert
        type={alertType}
        message={alertMsg}
        show={displayAlert}
        close={() => {
          setAlertMsg("");
          setDisplayAlert("none");
        }}
      />
      {/* <div className="card">
        <h4 className="card-header">Account Login</h4>
        <div className="card-body">
          Email:
          <br />
          <input
            className="form-control"
            type="email"
            value={email}
            onChange={(e) => {
              setError("");
              setEmail(e.target.value);
            }}
          />
          <p style={{ color: "red" }}>{error}</p>
          <button
            type="button"
            className="btn btn-primary mt-2"
            onClick={requestLoginLink}
          >
            Send Login Link
          </button>
        </div>
      </div> */}
      <div className={styles.login_wrapper} style={{backgroundImage:"url(/images/bg.png)"}}>
        <div className={styles.login_info}>
            <div className={styles.text_center}>
            {/* <img src="/images/logo.png" alt="" className={styles.logo_svg}/> */}
            </div>
            <div className={styles.login_form}>
                <div className={styles.text_center}>
                    <img src={logo2} className={styles.user_img} alt=""/>
                    <h1>Account Login</h1>
                </div>
             <form className={styles.login_form_info}>
                <label >Email</label>
                {/* <input type="text" placeholder="Enter here" className={styles.login_input}/> */}
                <input
            // className="form-control"
            type="email"
            placeholder="Enter here" 
            value={email}
            onChange={(e) => {
              setError("");
              setEmail(e.target.value);
            }}
            className={styles.login_input}
          />
                {/* <button className={styles.send_btn}>Send Login Link</button> */}
                <p style={{ color: "red" }}>{error}</p>
                <button
            type="button"
            className={styles.send_btn}
            onClick={requestLoginLink}
          >
            Send Login Link
          </button>
            </form>
        </div>
        </div>
    </div>
    </>
  );
};

export default PasswordlessLogin;
