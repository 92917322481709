import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import "../../src/pages/Analyst/css/index.css";
import { crossIcon, logo, logoutIcon,logo2 } from "../assets";
import $ from "jquery";

// Navbar
const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAnalyst, setIsAnalyst] = useState(false);
  const [hasReportingAccess, setHasReportingAccess] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isAccountActivated, setIsAccountActivated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [currentRole, setCurrentRole] = useState("");
  const navigate = useNavigate();

  // logout
  const logout = () => {
    window.localStorage.removeItem("accessToken");
    if (window.localStorage.getItem("isAnalyst")) {
      window.localStorage.removeItem("isAnalyst");
    }
    if (window.localStorage.getItem("isManager")) {
      window.localStorage.removeItem("isManager");
    }
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const token = window.localStorage.getItem("accessToken");
        if (token) {
          await axios
            .get(`${process.env.REACT_APP_API_URL}/api/v1/authenticated/me`, {
              headers: {
                authorization: "Bearer " + token,
              },
            })
            .then(function (response) {
              setIsAuthenticated(true);
              setIsAnalyst(response.data.IsAnalyst);
              setHasReportingAccess(response.data.HasReportingAccess);
              setIsManager(response.data.IsManager);
              setIsAdmin(response.data.IsAdministrator);
              setIsCustomer(response.data.IsCustomer);
              setIsAccountActivated(response.data.IsAccountActivated);
              if (response?.data?.IsAnalyst) {
                localStorage.setItem("isAnalyst", response?.data?.IsAnalyst);
              }
              if (response?.data?.IsManager) {
                localStorage.setItem("isManager", response?.data?.IsManager);
              }
              if (response?.data?.IsAdministrator) {
                localStorage.setItem(
                  "isAdministrator",
                  response?.data?.IsAdministrator
                );
              }
              if (response.data.IsAccountActivated === false) {
                navigate("/login");
              }
            });
        }
      } catch (err) {
        setIsAuthenticated(false);
      } finally {
        setTimeout(checkAuthentication, 1024);
      }
    };

    checkAuthentication();
  }, []);

  const handleClick = () => {
    $(".navbar-collapse").removeClass("show");
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };
  return (
    <nav class="navbar navbar-expand-lg yellow-back navbar-header top-header-nav">
      <div class="container">
        <Link to="/" class="navbar-brand navbar-brand-head p-0">
          <img src={logo2} class="img-fluid custom-navbar-brand" alt="" />
        </Link>
        <button
          class="navbar-toggler custom-nav-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse custom-navbar-collapse align-items-center"
          id="navbarNavDropdown"
        >
          <ul class="navbar-nav navbar-item-head  mx-auto gap-2 gap-xl-3">
            <li class="nav-item active">
              {(isAnalyst || isAdmin || isManager) && isAccountActivated && (
                <div class="dropdown nav-link py-3">
                  <a
                    class=" dropdown-toggle nav-link-text"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Analyst
                  </a>

                  <ul
                    class="dropdown-menu custom-drop-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <Link to="/Analyst/Create" class="dropdown-item">
                        Log Alerts
                      </Link>
                    </li>
                    <li>
                      <Link to="/Analyst/Import" class="dropdown-item">
                        Import CSV from SecurityOnion / Kibana
                      </Link>
                    </li>
                    <li>
                      <Link to="/Analyst/Index" class="dropdown-item">
                        Alerts
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li class="nav-item active">
              {hasReportingAccess && isAccountActivated  && (
                <div class="dropdown nav-link py-3">
                  <a
                    class=" dropdown-toggle nav-link-text"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Reporter
                  </a>
                  <ul
                    class="dropdown-menu custom-drop-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <Link to="/Reporter/Index" class="dropdown-item">
                        Get Report
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li class="nav-item active">
              {(isManager || isAdmin) && isAccountActivated && (
                <div class="dropdown nav-link py-3">
                  <a
                    class=" dropdown-toggle nav-link-text"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Manager
                  </a>

                  <ul
                    aria-disabled
                    class="dropdown-menu custom-drop-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <Link to="/Manager/manage-team" class="dropdown-item">
                        Manage Team
                      </Link>
                    </li>
                    <li>
                      <Link to="/Manager/create-user" class="dropdown-item">
                        Create User
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li class="nav-item active">
              {isAdmin && isAccountActivated && (
                <div class="dropdown nav-link py-3">
                  <a
                    class=" dropdown-toggle nav-link-text"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Administrator
                  </a>

                  <ul
                    class="dropdown-menu custom-drop-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <Link class="dropdown-item" to="/Admin/UndoCSVImport">
                        Undo CSV Import
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            {/* <li class="nav-item nav-switch-head">
              <div class="form-check form-switch nav-switch-body">
                <label class="form-check-label nav-link-text " for="flexSwitchCheckDefault">Ungrouped Alerts</label>
                <input class="form-check-input nav-check " type="checkbox" />
              </div>
            </li> */}
          </ul>

          {isAuthenticated && isAccountActivated ? (
            <div onClick={handleLogout} class="logout-link position-relative">
              <Link onClick={handleLogout} class="navbar-brand p-0">
                Logout
              </Link>
              <img src={logoutIcon} class="img-fluid log-icon" />
            </div>
          ) : (
            <div
              onClick={() => {
                navigate("/login");
              }}
              class="login-link position-relative"
            >
              <Link
                onClick={() => {
                  navigate("/login");
                }}
                class="navbar-brand p-0"
              >
                Login
              </Link>
              {/* <img src={require('./../assets/logout_icon.svg')} class="img-fluid log-icon" alt="" /> */}
            </div>
          )}

          <div onClick={handleClick} class="cross-icon-head">
            <img src={crossIcon} alt="" class="cross-icon" />
          </div>
        </div>
      </div>
    </nav>

    // <nav className="navbar navbar-expand-lg bg-light">
    //   <div className="container-fluid">
    //     <Link className="navbar-brand" to="/">
    //       Decept10
    //     </Link>
    //     <div className="collapse navbar-collapse" id="navbarNav">
    //       <ul className="navbar-nav">
    //         {isAnalyst && (
    //           <Dropdown className="dropdown-spacing">
    //             <Dropdown.Toggle variant="primary" id="dropdown-basic">
    //               Analyst
    //             </Dropdown.Toggle>

    //             <Dropdown.Menu>
    //               <Dropdown.Item as={Link} to="/Analyst/Create">
    //                 Log Alert
    //               </Dropdown.Item>
    //               <Dropdown.Item as={Link} to="/Analyst/Import">
    //                 Import CSV from SecurityOnion / Kibana
    //               </Dropdown.Item>
    //               <Dropdown.Item as={Link} to="/Analyst/Index">
    //                 Alerts
    //               </Dropdown.Item>
    //             </Dropdown.Menu>
    //           </Dropdown>
    //         )}

    //         {hasReportingAccess && (
    //           <Dropdown className="dropdown-spacing">
    //             <Dropdown.Toggle variant="primary" id="dropdown-basic">
    //               Reporter
    //             </Dropdown.Toggle>

    //             <Dropdown.Menu>
    //             <Dropdown.Item as={Link} to="/Reporter/Index">
    //                 Get Report
    //               </Dropdown.Item>
    //             </Dropdown.Menu>
    //           </Dropdown>
    //         )}

    //         {isManager && (
    //           <Dropdown className="dropdown-spacing">
    //             <Dropdown.Toggle variant="primary" id="dropdown-basic">
    //               Manager
    //             </Dropdown.Toggle>

    //             <Dropdown.Menu>
    //               <Dropdown.Item as={Link} to="/manage-team" disabled>
    //                 Manage Team
    //               </Dropdown.Item>
    //             </Dropdown.Menu>
    //           </Dropdown>
    //         )}

    //         {isAdmin && (
    //           <Dropdown className="dropdown-spacing">
    //             <Dropdown.Toggle variant="primary" id="dropdown-basic">
    //               Administrator
    //             </Dropdown.Toggle>

    //             <Dropdown.Menu>
    //               <Dropdown.Item as={Link} to="/Admin/UndoCSVImport">
    //                 Undo CSV Import
    //               </Dropdown.Item>
    //             </Dropdown.Menu>
    //           </Dropdown>
    //         )}
    //       </ul>
    //     </div>
    //     <ul className="navbar-nav ml-auto">
    //       {isAuthenticated && (
    //         <li className="nav-item">
    //           <a className="nav-link" onClick={logout}>
    //             Logout
    //           </a>
    //         </li>
    //       )}

    //       {!isAuthenticated && (
    //         <>
    //           <li className="nav-item">
    //             <Link to="/login" className="nav-link">
    //               Login
    //             </Link>
    //           </li>
    //         </>
    //       )}
    //     </ul>
    //   </div>
    // </nav>
  );
};

export default Navbar;
