import {useState, useEffect} from "react";
import axios from "axios";

// This component will check if the user is authenticated and has reporting access before loading the page.
const ReportingAccessPageLoad = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [hasReportingAccess, setHasReportingAccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const refreshToken = async () => {
        try {
            const token = window.localStorage.getItem("accessToken");

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/refresh`, {}, {
                headers: {
                    "authorization": "Bearer " + token,
                }
            });

            window.localStorage.setItem("accessToken", response.data);
        } catch (err) {
            console.error("Error while refreshing token: ", err);
        }
    }

    // Check if the user is authenticated and has reporting access.
    useEffect(() => {
        try {
            const token = window.localStorage.getItem("accessToken");

            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/authenticated/me`, {
                headers: {
                    "authorization": "Bearer " + token,
                }
            }).then(function(response) {
                setIsAuthenticated(true);
                setIsLoading(false);
                // Assume "HasReportingAccess" is a field in the response data.
                setHasReportingAccess(response.data.HasReportingAccess);
                refreshToken(); // Refresh the token after successful authentication
            }).catch(function(err) {
                setIsAuthenticated(false);
                setIsLoading(false);
            })
        } catch (err) {
            setIsAuthenticated(false);
            setIsLoading(false);
        }
    }, [])

    // If the user is authenticated and has reporting access, load the page, otherwise, display a waiting message or an error message.
    if (isLoading) {
        return (
            <div className="card">
                <h4 className="card-header">Checking Credentials</h4>
                <div className="card-body">
                    <p>Please wait while we check your credentials!</p>
                </div>
            </div>
        );
    }

    if (isAuthenticated && hasReportingAccess) {
        return children;
    } else if (!isLoading && (!isAuthenticated || !hasReportingAccess)) {
        return (
            <div className="card">
                <h4 className="card-header">Access Denied</h4>
                <div className="card-body">
                    <p>You must be authenticated and have reporting access to view this page!</p>
                </div>
            </div>
        )
    }
}

export default ReportingAccessPageLoad;
