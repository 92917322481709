import { useState, useEffect } from "react";
import axios from "axios";

// This component will check if the user is authenticated before loading the page.
const AuthenticatedPageLoad = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const refreshToken = async () => {
        try {
            const token = window.localStorage.getItem("accessToken");

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/refresh`, {}, {
                headers: {
                    "authorization": "Bearer " + token,
                }
            });

            window.localStorage.setItem("accessToken", response.data);
        } catch (err) {
            console.error("Error while refreshing token: ", err);
        }
    }

    // Check if the user is authenticated.
    useEffect(() => {
        try {
            const token = window.localStorage.getItem("accessToken");

            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/authenticated/me`, {
                headers: {
                    "authorization": "Bearer " + token,
                }
            }).then(function (response) {
                setIsAuthenticated(true);
                setIsLoading(false);
                window.localStorage.setItem("analyst_name", response?.data?.Name)
                window.localStorage.setItem("customer_index_name", response?.data?.Name)
                window.localStorage.setItem("analyst_email", response?.data?.Email)
                refreshToken(); // Refresh the token after successful authentication
            }).catch(function (err) {
                setIsAuthenticated(false);
                setIsLoading(false);
            })
        } catch (err) {
            setIsAuthenticated(false);
            setIsLoading(false);
        }
    }, [])

    // If the user is authenticated, load the page, otherwise, display a waiting message.
    if (isLoading) {
        return (
            <div className="card">
                <h4 className="card-header">Checking Credentials</h4>
                <div className="card-body">
                    <p>Please wait while we check your credentials!</p>
                </div>
            </div>
        );
    }

    console.log("setIsAuthenticated:",setIsAuthenticated);
    // If the user is authenticated, load the page, otherwise, display an error message.
    if (isAuthenticated) {
        return children;
    } else if (!isLoading && !isAuthenticated) {
        return (
            <div className="card">
                <h4 className="card-header">Authentication Required</h4>
                <div className="card-body">
                    <p>You must be authenticated to view this page!</p>
                </div>
            </div>
        )
    }
}

export default AuthenticatedPageLoad;
