import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CSVReader from "react-csv-reader";

const EventFormBulkUpload = () => {
  const [displayAlert, setDisplayAlert] = useState("none");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType] = useState("danger");
  const [customerName, setCustomerName] = useState("viperlineoffice");
  const [analystName, setAnalystName] = useState(
    localStorage.getItem("analyst_name")
  );
  const [event, setEvent] = useState("");
  const [impact, setImpact] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [allCustomers, setAllCustomners] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const submitTime = new Date().toISOString();

  const handleFileLoad = (data, fileInfo) => {
    setCsvData(data);
  };

  const getFormattedDate = (timeToFormat) => {
    // Check that str is a string
    if (typeof timeToFormat !== "string") {
      throw new TypeError("Expected a string");
    }

    // Split str into date and time parts
    let parts = timeToFormat.split(" @ "); // notice the extra spaces
    console.log("Parts:", parts);

    // Trim whitespace and split date into month, day, and year
    let dateParts = parts[0].split(" ");
    console.log("Date parts:", dateParts);

    // Trim whitespace and split time into main and fractional seconds
    let timeParts = parts[1];
    console.log("Time parts:", timeParts);

    // Assign parts to variables
    let [month, dayWithComma, year] = dateParts;
    console.log(
      "Month:",
      month,
      "Day with comma:",
      dayWithComma,
      "Year:",
      year
    );

    var currentDate = new Date();
    var currentDay = currentDate.getDate();
    let day = currentDay;

    try {
      day = Number(dayWithComma);
    } catch {
      let today = new Date();
      let dayOfMonth = today.getDate();

      console.log(dayOfMonth); // Prints today's date (e.g., 3 if today is the 3rd of the month)
      day = Number(dayOfMonth); // failsafe to just use today's number if all else fails!
    }
    console.log("Day:", day);

    // Map month name to number
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthNumber = monthNames.indexOf(month) + 1;
    if (monthNumber === -1) {
      throw new Error("Invalid month name");
    }
    console.log("Month number:", monthNumber);
    // Create date string in YYYY-MM-DDTHH:mm:ss format
    let dateString = `${year}-${
      monthNumber < 10 ? "0" + monthNumber : monthNumber
    }-${day < 10 ? "0" + day : day}T${timeParts}`;

    console.log("Date string:", dateString);
    return dateString;
  };

  const fetchCustomers = async () => {
    setLoading(true);

    const token = window.localStorage.getItem("accessToken");

    if (token) {
      let url;
      if (localStorage.getItem("isAnalyst") === false) {
        url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/all-user-listing`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/all-user-listing`;
      }
      await axios
        .get(url, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          const customerArr = response?.data?.data?.filter((customer) => {
            return customer?.IsCustomer;
          });

          setAllCustomners(customerArr);
          // Added null checks
          // setPageCount(Math.ceil((response?.data?.total || 0) / pageSize));
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          // Add additional error handling here as needed
          setAlertMsg("Error fetching data. Please try again later.");
          setDisplayAlert("block");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = async () => {
    const bulkData = csvData.map(
      ([timestamp, sourceIP, sourcePort, destinationIP, destinationPort]) => ({
        event: event + "   (CSV Import @ " + submitTime + ")",
        impact: impact,
        sourceIP,
        sourcePort,
        destinationIP,
        destinationPort,
        customerName: customerName,
        analystName: analystName,
        timestamp: getFormattedDate(timestamp),
      })
    );

    const token = window.localStorage.getItem("accessToken");

    if (token) {
      try {
        await Promise.all(
          bulkData.map((eventData) =>
            axios.post(
              `${process.env.REACT_APP_API_URL}/api/v1/authenticated/events`,
              eventData,
              {
                headers: {
                  authorization: "Bearer " + token,
                },
              }
            )
          )
        );
      } catch (error) {
        console.error(error);
      } finally {
        navigate("/Analyst/Import");
      }
    }
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <section className="card-form ">
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <div className="card card-log-head my-3">
              <h4 className="card-header text-center">
                Import CSV from SecurityOnion / Kibana
              </h4>
              <div className="card-body">
                <p>
                  Use this form to bulk upload alerts into the system. This will
                  be a file exported from Kibana called "Security Onion - All
                  Logs.csv". Each case will include the import time in the
                  title, email nathaniel@viperline.com if you accidentally
                  import and need a bulk delete.
                </p>

                <p className="log-form-head">Event:</p>
                <input
                  className="form-control"
                  type="text"
                  value={event}
                  onChange={(e) => setEvent(e.target.value)}
                />
                <br />
                <p className="log-form-head"> Impact: </p>
                <textarea
                  className="form-control"
                  value={impact}
                  onChange={(e) => setImpact(e.target.value)}
                />
                <br />
                <p className="log-form-head"> CSV Upload: </p>
                <CSVReader
                  onFileLoaded={handleFileLoad}
                  inputId="csvUpload"
                  inputStyle={{ color: "red" }}
                />
                <br />
                <p className="log-form-head"> Customer Name: </p>
                <select
                  className="form-control"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                >
                  {/* <option value="viperlineoffice">Viperline Office</option>
                  <option value="tcss">Tuscaloosa County School System</option>
                  <option value="mps">Montgomery Public Schools</option>
                  <option value="bhm">Birmingham City Schools</option>
                  <option value="sev">Sevierville</option>
                  <option value="lee">Lee University</option>
                  <option value="cle">Cleveland</option> */}
                  {allCustomers?.map((customer) => {
                    return (
                      <option value={customer?.Name}>{customer?.Name}</option>
                    );
                  })}
                </select>
                <br />
                <p className="log-form-head"> Analyst Name: </p>
                <select
                  className="form-control"
                  value={analystName}
                  onChange={(e) => setAnalystName(e.target.value)}
                >
                  <option value={window.localStorage.getItem("analyst_name")}>
                    {window.localStorage.getItem("analyst_name")}
                  </option>
                  {/* <option value="">Select your name from the dropdown</option>
                                    <option value="amer">Amer</option>
                                    <option value="brooke">Brooke</option>
                                    <option value="ian">Ian</option>
                                    <option value="mo">Mo</option>
                                    <option value="nathaniel">Nathaniel</option> */}
                </select>
                <br />
                <button
                  className="btn btn-primary mt-2 log-form-submit"
                  onClick={handleSubmit}
                >
                  Submit Alerts
                </button>
              </div>
            </div>
            {/* <div className="card card-log-head my-3">
                        <h4 className="card-header text-center">Undo CSV Import</h4>
                        <div className="card-body cardheight">
                            <input
                                type="text"
                                className="form-control"
                                value={csvId}
                                onChange={(e) => setCsvId(e.target.value)}
                                placeholder="(CSV Import @ 2023-06-09T15:41:33.586Z)"
                            />
                            <button className="btn btn-primary mt-2 log-form-submit" onClick={handleDelete}>Delete Import</button>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventFormBulkUpload;
