// Desc: Alert component
const Alert = (props) => {
    return (
        <div className={`alert alert-${props.type} alert-dismissible`} role="alert" style={{"display": props.show}}>
            {props.message}
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={props.close}
            ></button>
        </div>
    )
}

export default Alert;