import React, { useEffect, useState } from "react";
import axios from "axios";
import { Switch, Select, Input, Space } from "antd";
import { useTable, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Alert from "../../partials/Alert";
import {
  arrowLeft,
  arrowRight,
  checked,
  crossRed,
  orange,
  red,
  searchIcon,
  yellow,
} from "../../assets";
import CommentModal from "./CommentModal";
const CustomerIndex = () => {
  const [displayAlert, setDisplayAlert] = useState("none");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType] = useState("danger");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [customer, setCustomer] = useState("");
  const [isCustomer, setIsCustomer] = useState(false);
  const [analyst, setAnalyst] = useState("");
  const [timeFilter, setTimeFilter] = useState("72h");
  const [rowExpandState, setRowExpandState] = useState({});
  let [grouped, setGrouped] = useState(false); // ungrouped by default
  const { Option } = Select;
  const { Search } = Input;
  const fetchIdRef = React.useRef(0);
  const pageSize = 10; // set the number of items per page
  const navigate = useNavigate(); // Instantiate useHistory hook
  const [refreshInterval, setRefreshInterval] = useState(10000);
  const [autoRefresh, setAutoRefresh] = useState(
    localStorage.getItem("autoRefresh") === "true" || true
  );
  const [showCommentModal, setShowCommentModal] = useState(false)
  const [comment, setComment] = useState(null);
  const [error, setError] = useState("");
  const [rowToResolve, setRowToResolve] = useState(null);

  // Time options
  const timeOptions = [
    { label: "Last hour", value: "1h" },
    { label: "Last day", value: "24h" },
    { label: "Last 3 days", value: "72h" },
    { label: "Last week", value: "1w" },
    { label: "Last month", value: "1m" },
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: "Event Title",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`event_${row?.original?.id}`] ? (
              <>
 <p>{row?.original?.event}</p>
{row?.original?.event?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`event_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "event")}
                  >
                    {rowExpandState[`event_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.event?.length > 170
                    ? row?.original?.event?.slice(0, 170) + "..."
                    : row?.original?.event}
                </p>
                {row?.original?.event?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`event_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "event")}
                  >
                    {rowExpandState[`event_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Impact Details",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`impact_${row?.original?.id}`] ? (
              <>
 <p>{row?.original?.impact}</p>
{row?.original?.impact?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`impact_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "impact")}
                  >
                    {rowExpandState[`impact_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.impact?.length > 170
                    ? row?.original?.impact?.slice(0, 170) + "..."
                    : row?.original?.impact}
                </p>
                {row?.original?.impact?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`impact_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "impact")}
                  >
                    {rowExpandState[`impact_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Source IP",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`sourceIP_${row?.original?.id}`] ? (
              <>
 <p>{row?.original?.sourceIP}</p>
{row?.original?.sourceIP?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`sourceIP_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "sourceIP")}
                  >
                    {rowExpandState[`sourceIP_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.sourceIP?.length > 170
                    ? row?.original?.sourceIP?.slice(0, 170) + "..."
                    : row?.original?.sourceIP}
                </p>
                {row?.original?.sourceIP?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`sourceIP_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "sourceIP")}
                  >
                    {rowExpandState[`sourceIP_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Source Port",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`sourcePort_${row?.original?.id}`] ? (
              <>
 <p>{row?.original?.sourcePort}</p>
{row?.original?.sourcePort?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`sourcePort_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "sourcePort")}
                  >
                    {rowExpandState[`sourcePort_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.sourcePort?.length > 170
                    ? row?.original?.sourcePort?.slice(0, 170) + "..."
                    : row?.original?.sourcePort}
                </p>
                {row?.original?.sourcePort?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`sourcePort_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "sourcePort")}
                  >
                    {rowExpandState[`sourcePort_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Destination IP",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`destinationIP_${row?.original?.id}`] ? (
              <>
 <p>{row?.original?.destinationIP}</p>
{row?.original?.destinationIP?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`destinationIP_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "destinationIP")}
                  >
                    {rowExpandState[`destinationIP_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.destinationIP?.length > 170
                    ? row?.original?.destinationIP?.slice(0, 170) + "..."
                    : row?.original?.destinationIP}
                </p>
                {row?.original?.destinationIP?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`destinationIP_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "destinationIP")}
                  >
                    {rowExpandState[`destinationIP_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Destination Port",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`destinationPort_${row?.original?.id}`] ? (
              <>
 <p>{row?.original?.destinationPort}</p>
{row?.original?.destinationPort?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`destinationPort_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "destinationPort")}
                  >
                    {rowExpandState[`destinationPort_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.destinationPort?.length > 170
                    ? row?.original?.destinationPort?.slice(0, 170) + "..."
                    : row?.original?.destinationPort}
                </p>
                {row?.original?.destinationPort?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`destinationPort_${row?.original?.id}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.id, "destinationPort")}
                  >
                    {rowExpandState[`destinationPort_${row?.original?.id}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Security Event Timestamp",
        accessor: "timestamp",
      },
      {
        Header: "Event Severity",
        Cell: ({ row }) => (
          <div>
            {row?.original?.eventSeverity === 1 && (
              <div>
                <img style={{ width: 24, height: 24 }} src={red} />
              </div>
            )}
  
            {row?.original?.eventSeverity === 2 && (
              <div>
                {" "}
                <img style={{ width: 24, height: 24 }} src={orange} />
              </div>
            )}
  
            {row?.original?.eventSeverity === 3 && (
              <div>
                <img style={{ width: 24, height: 24 }} src={yellow} />
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Resolved",
        Cell: ({ row }) => (
          <div>
            {row?.original?.resolved === 1 && (
              <div>
                <img
                  onClick={() => handleCommentModal(row?.original) }
                  style={{ width: 24, height: 24 }}
                  src={crossRed}
                />
              </div>
            )}

            {row?.original?.resolved === 2 && (
              <div>
                <img style={{ width: 24, height: 24 }} src={checked} />
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Resolve Comments",
        accessor: "comments",
      }
    ],
    [rowExpandState]
  );

  const handleReadMore = (rowId, field) => {
    console.log(rowId, "rowId build built ");
    console.log(rowExpandState[`event_${rowId}`], "rowId build built");
    setRowExpandState((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  
    // Handle expanding/collapsing individual cells based on the field
    if (field === "event") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`event_${rowId}`]: !prevState[`event_${rowId}`],
      }));
    } else if (field === "impact") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`impact_${rowId}`]: !prevState[`impact_${rowId}`],
      }));
    }else if (field === "sourceIP") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`sourceIP_${rowId}`]: !prevState[`sourceIP_${rowId}`],
      }));
    }
    else if (field === "sourcePort") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`sourcePort_${rowId}`]: !prevState[`sourcePort_${rowId}`],
      }));
    }
    else if (field === "destinationIP") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`destinationIP_${rowId}`]: !prevState[`destinationIP_${rowId}`],
      }));
    }
    else if (field === "destinationPort") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`destinationPort_${rowId}`]: !prevState[`destinationPort_${rowId}`],
      }));
    }
  };
  const handleValidation = () => {
    let isValidate = true;
    if (comment === "") {
      setError("Please enter Comment");
      isValidate = false;
    }
    return isValidate;
  };
  const handleCommentModal=(row)=>{
    setShowCommentModal(true);
    setRowToResolve(row);
  }

  const handleEditEvent = (row) => {
    // const userInput = window.prompt("Please enter your comment:");
    if (handleValidation()) {
      let rowCopy = row;
      delete row["createdAt"];
      row["timestamp"] = row["timestamp"].replace("Z", "").replace("T", " ");
      row["resolved"] = 2;
      row["destinationPort"] = row["destinationPort"].toString();
      row["sourcePort"] = row["sourcePort"].toString();
      let token = window.localStorage.getItem("accessToken");
      let apiData = {
        destinationIP: row.destinationIP,
        destinationPort: row.destinationPort,
        event: row.event,
        eventSeverity: row.eventSeverity,
        CustomerName: row.customerName,
        AnalystName: row.analystName,
        id: row.id,
        impact: row.impact,
        resolved: 2,
        sourceIP: row.sourceIP,
        sourcePort: row.sourcePort,
        timestamp: row.timestamp.replace(
          /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})Z$/,
          "$1 $2"
        ),
        timestampend: row.timestampend.replace(
          /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})Z$/,
          "$1 $2"
        ),
        comments: comment,     
      };
      let config = {
        method: "put",
        url:
          `${process.env.REACT_APP_API_URL}/api/v1/authenticated-customer/event/` +
          row?.id,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: apiData,
      };

      let newData = [...data];
      axios
        .request(config)
        .then((response) => {
          newData.forEach((element) => {
            if (element.id == row?.id) {
              rowCopy["resolved"] = 2;
              element = rowCopy;
              setShowCommentModal(false);
              setRowToResolve(null);
              fetchData();
            }
          });
          setData(newData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // const fetchData = async ({ pageSize, pageIndex }) => {
  //   const fetchId = ++fetchIdRef.current;

  //   setLoading(true);

  //   if (fetchId === fetchIdRef.current) {
  //     const token = window.localStorage.getItem("accessToken");
  //     if (token) {
  //       await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/customer-listing`, {
  //           headers: {
  //               "authorization": "Bearer " + token,
  //           }
  //       })
  //       .then(function (response) {
  //         setData(response.data)
  //         setPageCount(Math.ceil((response?.data?.total || 0) / pageSize));

  //       })
  //       .catch(function (error) {
  //           alert("Something went wrong. Please try again")
  //       });
  //   }

  //     if (token) {
  //       let timestamp = '';
  //       switch (timeFilter) {
  //         case '1h': timestamp = moment().subtract(1, 'hours').toISOString(); break;
  //         case '24h': timestamp = moment().subtract(1, 'days').toISOString(); break;
  //         case '72h': timestamp = moment().subtract(3, 'days').toISOString(); break;
  //         case '1w': timestamp = moment().subtract(1, 'weeks').toISOString(); break;
  //         case '1m': timestamp = moment().subtract(1, 'months').toISOString(); break;
  //         default: timestamp = '';
  //       }
  //       const url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/customer-listing}` : '');
  //       await axios.get(url, {
  //         headers: {
  //           "authorization": "Bearer " + token,
  //         }
  //       })
  //         .then(response => {
  //           setData(response?.data?.data || []); // Added null checks
  //           setPageCount(Math.ceil((response?.data?.total || 0) / pageSize));
  //         })
  //         .catch(error => {
  //           console.log('Error fetching data:', error);
  //           // Add additional error handling here as needed
  //           setAlertMsg("Error fetching data. Please try again later.");
  //           setDisplayAlert("block");
  //         })
  //         .finally(() => {
  //           setLoading(false);
  //         });
  //     }
  //   }
  // }

  const fetchData = async () => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);

    if (fetchId === fetchIdRef.current) {
      const token = window.localStorage.getItem("accessToken");

      if (token) {
        let timestamp = "";
        switch (timeFilter) {
          case "1h":
            timestamp = moment().subtract(1, "hours").toISOString();
            break;
          case "24h":
            timestamp = moment().subtract(1, "days").toISOString();
            break;
          case "72h":
            timestamp = moment().subtract(3, "days").toISOString();
            break;
          case "1w":
            timestamp = moment().subtract(1, "weeks").toISOString();
            break;
          case "1m":
            timestamp = moment().subtract(1, "months").toISOString();
            break;
          default:
            timestamp = "";
        }
        let url;
        // if (isCustomer) {
        url = `${
          process.env.REACT_APP_API_URL
        }/api/v1/authenticated-customer/events?pageSize=${pageSize}&page=${
          pageIndex + 1 + (timestamp ? `&createdAfter=${timestamp}` : "")
        }`;
        // } else {
        //   url = `${
        //     process.env.REACT_APP_API_URL
        //   }/api/v1/authenticated/manager-events?pageSize=${pageSize}&page=${
        //     pageIndex + 1 + (timestamp ? `&createdAfter=${timestamp}` : "")
        //   }`;
        // }
        // const url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated-customer/events`;
        await axios
          .get(url, {
            headers: {
              authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            console.log(response, "response..");
            const filteredData = response?.data?.data?.filter((item) =>
              item?.event?.toLowerCase().includes(searchTerm.toLowerCase())
            );

            setData(filteredData || []);
            // setData(response?.data?.data || []); // Added null checks
            setPageCount(Math.ceil((response?.data?.total || 0) / pageSize));
          })
          .catch((error) => {
            console.log("Error fetching data:", error);
            // Add additional error handling here as needed
            setAlertMsg("Error fetching data. Please try again later.");
            setDisplayAlert("block");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize: statePageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: pageCount,
    },
    usePagination
  );

  useEffect(() => {
    let intervalId;
    const startAutoRefresh = () => {
      fetchData();
      intervalId = setInterval(fetchData, refreshInterval);
    };

    if (autoRefresh) {
      startAutoRefresh();
    } else {
      fetchData();
    }
    // Auto-refresh interval
    // const intervalId = setInterval(() => {
    //   fetchData();
    // }, refreshInterval);

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [
    customer,
    searchTerm,
    analyst,
    pageIndex,
    pageSize,
    timeFilter,
    grouped,
    refreshInterval,
    autoRefresh,
  ]);

  const toggleAutoRefresh = () => {
    const newAutoRefresh = !autoRefresh;
    setAutoRefresh(newAutoRefresh);
    localStorage.setItem("autoRefresh", newAutoRefresh);
  };
  useEffect(() => {
    try {
      const token = window.localStorage.getItem("accessToken");

      axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/authenticated/me`, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          setIsCustomer(response?.data?.IsCustomer);
        })
        .catch(function (err) {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Alert
        type={alertType}
        message={alertMsg}
        show={displayAlert}
        close={() => setDisplayAlert("none")}
      />

      <div
        className={
          data?.length > 0
            ? "top-header-new top-header header-height"
            : "top-header header-height"
        }
      >
        <section class="analyst-dahboard">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 pt-0 pt-lg-3 pb-2 position-relative">
                <h1>
                  <span class="welcome-head">Welcome to the</span>
                  <br></br>
                  <span class="welcome-head-left">Decept 10</span>
                  <span class="welcome-head-right">Customer Dashboard</span>
                </h1>
                <Switch
                  checked={autoRefresh}
                  onChange={toggleAutoRefresh}
                  checkedChildren="Auto Refresh On"
                  unCheckedChildren="Auto Refresh Off"
                  className="switch-toggle"
                />
              </div>
              <div class="col-lg-6 pb-3 pt-3 pt-sm-0">
                <div class="main-filter d-flex gap-2 gap-md-3 gap-xl-5">
                  <div class="input-group search-input-head w-75">
                    <input
                      type="search"
                      class="form-control custom-drop drop-new"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search by Event Title"
                      aria-label="Search"
                      aria-describedby="search-addon"
                    />
                    <img src={searchIcon} class="search-input-icon" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 pb-3">
                <div class="main-filter d-flex gap-2 gap-md-3 gap-xl-5">
                  <div class="dropdown last-drop">
                    <Select
                      className="custom-drop"
                      placeholder="Select time period"
                      onChange={(value) => setTimeFilter(value)}
                      value={timeFilter}
                    >
                      {timeOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bottom-section pt-5">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive main-table">
                  <table {...getTableProps()} className="table">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                          {/* <th>View More</th> */}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            className="row-content short"
                          >
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            ))}
                            {/* <td>
                              <button
                                className={
                                  rowExpandState[row?.original?.id]
                                    ? "btn btn-primary mb-2"
                                    : "btn btn-success mb-2"
                                }
                                onClick={() =>
                                  handleReadMore(row?.original?.id)
                                }
                              >
                                {rowExpandState[row?.original?.id]
                                  ? "Read Less"
                                  : "Read More"}
                              </button>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center align-items-center mt-4 mb-5">
                    <div className="pagination-head">
                      <button
                        className="btn btn-outline-secondary me-2"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        {"first"}
                      </button>
                      <button
                        className="me-3 p-0 border-0 bg-transparent me-3"
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                      >
                        <img src={arrowLeft} />
                      </button>
                      <span>Page {pageIndex + 1}</span>
                      <button
                        className="me-3 p-0 border-0 bg-transparent ms-3"
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        <img src={arrowRight} />
                      </button>
                      <button
                        className="btn btn-outline-secondary me-2"
                        type="button"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        {"last"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
{showCommentModal &&
  <CommentModal
showCommentModal={showCommentModal}
setShowCommentModal={setShowCommentModal}
comment={comment}
setComment={setComment}
error={error}
setError={setError}
handleEditEvent={handleEditEvent}
rowToResolve={rowToResolve}
setRowToResolve={setRowToResolve}
/>}
      {/* <div className="card">
        <div className='card-header'>
          <h4 className='card-title text-center'>Welcome to the Decept10 Customer Dashboard</h4>
        </div>
        <div className="card-body">
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '10px' }}>
            <Search
              placeholder="Search by Event Title"
              onSearch={value => setSearchTerm(value)}
              style={{ width: 240, marginTop: 7 }}
            />

            <Select
              style={{ width: 240, marginTop: 7 }}
              placeholder="Select time period"
              onChange={value => setTimeFilter(value)}
              value={timeFilter}
            >
              {timeOptions.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>

            <Switch
              checked={grouped}
              onChange={() => setGrouped(!grouped)}
              checkedChildren="Grouped Alerts"
              unCheckedChildren="Ungrouped Alerts"
              style={{ marginTop: '1em', marginLeft: '1em' }}
            />

          </div>

          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div> */}

      {/* <div className="d-flex justify-content-center align-items-center mt-4 mb-5">
        <div className='pagination-head'>
          <button className="me-3 p-0 border-0 bg-transparent me-3" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <img src={arrowLeft} />
          </button>
          <span>
            Page{' '}{pageIndex + 1}
          </span>
          <button className="me-3 p-0 border-0 bg-transparent ms-3" onClick={() => alert("Hello")} disabled={!canNextPage}>
            <img src={arrowRight} />
          </button>
        </div>

      </div> */}

      {/* <div className="d-flex justify-content-between align-items-center my-3">
        <div>
          <button className="btn btn-outline-secondary me-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>
          <button className="btn btn-outline-secondary me-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>
          <button className="btn btn-outline-secondary me-2" onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>
          <button className="btn btn-outline-secondary me-2" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>
        </div>
        <div>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span className="ms-2">
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: '100px' }}
              className="form-control mx-2 d-inline-block"
            />
          </span>
          <select
            value={statePageSize} // use statePageSize here
            onChange={e => {
              setPageSize(Number(e.target.value));
              fetchData({ pageIndex, pageSize: Number(e.target.value) });
            }}
            className="form-select"
          >
            {[10, 20, 30, 40, 50].map(size => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div> */}

      {loading && <div>Loading...</div>}
    </>
  );
};

export default CustomerIndex;
