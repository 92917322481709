export const logoutIcon = '/images/logout_icon.svg'
export const searchIcon = '/images/search_icon.svg'
export const crossIcon = '/images/cross_icon.svg'
export const arrowLeft = '/images/arrow_left.svg'
export const arrowRight = '/images/arrow_right.svg'
export const logo = '/images/decept_logo.png'
export const logo2 = '/images/logo_img.png'
export const yellow = '/images/yellow.svg'
export const orange = '/images/orange.svg'
export const red = '/images/red.svg'
export const checked = '/images/check.svg'
export const crossRed = '/images/cross-red.svg'
export const bottomToTop = '/images/arrow_up.svg'