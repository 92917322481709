import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import qs from "qs";

const UndoCSVImport = () => {
  const [csvId, setCsvId] = useState("");
  const navigate = useNavigate();

  const isValidCSVId = (input) => {
    const regex = /\(CSV Import @ \d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z\)/;
    return regex.test(input);
  };

  const handleDelete = async () => {
    const token = window.localStorage.getItem("accessToken");

    if (!isValidCSVId(csvId)) {
      alert(
        "Invalid CSV ID. Please input a CSV ID that matches the format '(CSV Import @ 2023-06-09T15:41:33.586Z)'."
      );
      return;
    } else if (token) {
      try {
        const csvImportString = csvId; // Replace this with your actual CSV import string

        const requestBody = qs.stringify({ csvImport: csvImportString });

        axios
          .delete("/api/v1/authenticated/admin/events", {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + token, // replace 'token' with your actual token
            },
            data: requestBody,
          })
          .then((response) => {})
          .catch((error) => {
            console.error("Error:", error);
          });

        navigate("/alerts");
      } catch (error) {
        console.error("Error during deletion:", error);
      }
    }
  };

  return (
    <section className="card-form ">
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <div className="card card-log-head my-3">
              <h4 className="card-header text-center">Undo CSV Import</h4>
              <div className="card-body cardheight">
                <input
                  type="text"
                  className="form-control"
                  value={csvId}
                  onChange={(e) => setCsvId(e.target.value)}
                  placeholder="(CSV Import @ 2023-06-09T15:41:33.586Z)"
                />
                <button
                  className="btn btn-primary mt-2 log-form-submit"
                  onClick={handleDelete}
                >
                  Delete Import
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UndoCSVImport;
