import React, { useEffect, useState } from "react";
import axios from "axios";
import { Switch, Select, Input, Space } from "antd";
import { useTable, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Alert from "../../partials/Alert";
import {
  arrowLeft,
  arrowRight,
  bottomToTop,
  checked,
  crossIcon,
  crossRed,
  green,
  orange,
  red,
  searchIcon,
  yellow,
} from "../../assets";
import { data } from "jquery";
import CustomerIndex from "../Customer/CustomerIndex";

const AlertsTable = () => {
  const [displayAlert, setDisplayAlert] = useState("none");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType] = useState("danger");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [customer, setCustomer] = useState("");
  // const [isUserCustomer, setIsUserCustomer] = useState(false);
  const [analyst, setAnalyst] = useState("");
  const [showMore, setShowMore] = useState(true);
  const [rowExpandState, setRowExpandState] = useState({});
  const [timeFilter, setTimeFilter] = useState("72h");
  let [grouped, setGrouped] = useState(false); // ungrouped by default
  const { Option } = Select;
  const { Search } = Input;
  const fetchIdRef = React.useRef(0);
  const pageSize = 20; // set the number of items per page
  const navigate = useNavigate(); // Instantiate useHistory hook
  const [read, setRead] = useState(true);
  const [allCustomers, setAllCustomners] = useState([]);
  const [allAnalysts, setAllAnalysts] = useState([]);
  // Time options
  const timeOptions = [
    { label: "Last hour", value: "1h" },
    { label: "Last day", value: "24h" },
    { label: "Last 3 days", value: "72h" },
    { label: "Last week", value: "1w" },
    { label: "Last month", value: "1m" },
  ];

  const handleReadMore = (rowId, field) => {
    console.log(rowId, "rowId build built ");
    console.log(rowExpandState[`event_${rowId}`], "rowId build built");
    setRowExpandState((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  
    // Handle expanding/collapsing individual cells based on the field
    if (field === "Event") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`Event_${rowId}`]: !prevState[`Event_${rowId}`],
      }));
    } else if (field === "Impact") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`Impact_${rowId}`]: !prevState[`Impact_${rowId}`],
      }));
    }else if (field === "SourceIP") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`SourceIP_${rowId}`]: !prevState[`SourceIP_${rowId}`],
      }));
    }
    else if (field === "SourcePort") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`SourcePort_${rowId}`]: !prevState[`SourcePort_${rowId}`],
      }));
    }
    else if (field === "DestinationIP") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`DestinationIP_${rowId}`]: !prevState[`DestinationIP_${rowId}`],
      }));
    }
    else if (field === "DestinationPort") {
      setRowExpandState((prevState) => ({
        ...prevState,
        [`DestinationPort_${rowId}`]: !prevState[`DestinationPort_${rowId}`],
      }));
    }
  };

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Event Title",
      //   accessor: "Event",
      // },
      {
        Header: "Event Title",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`Event_${row?.original?.ID}`] ? (
              <>
 <p>{row?.original?.Event}</p>
{row?.original?.Event?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`Event_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "Event")}
                  >
                    {rowExpandState[`Event_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.Event?.length > 170
                    ? row?.original?.Event?.slice(0, 170) + "..."
                    : row?.original?.Event}
                </p>
                {row?.original?.Event?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`Event_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "Event")}
                  >
                    {rowExpandState[`Event_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      // {
      //   Header: "Impact Details",
      //   accessor: "Impact",

      //   Cell: ({ row }) => {
      //     return rowExpandState[row?.original?.ID] ? (
      //       <p>{row?.original?.Impact}</p>
      //     ) : (
      //       <p>{row?.original?.Impact.slice(0, 50) + "..."}</p>
      //     );
      //   },
      // },
      {
        Header: "Impact Details",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`Impact_${row?.original?.ID}`] ? (
              <>
 <p>{row?.original?.Impact}</p>
{row?.original?.Impact?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`Impact_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "Impact")}
                  >
                    {rowExpandState[`Impact_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.Impact?.length > 170
                    ? row?.original?.Impact?.slice(0, 170) + "..."
                    : row?.original?.Impact}
                </p>
                {row?.original?.Impact?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`Impact_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "Impact")}
                  >
                    {rowExpandState[`Impact_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      // {
      //   Header: "Source IP",
      //   // accessor: 'SourceIP',
      //   Cell: ({ row }) =>
      //     rowExpandState[row?.original?.ID] ? (
      //       <p>{row?.original?.SourceIP}</p>
      //     ) : row?.original?.SourceIP.length < 50 ? (
      //       <p>{row?.original?.SourceIP}</p>
      //     ) : (
      //       <p>{row?.original?.SourceIP.slice(0, 50) + "..."}</p>
      //     ),
      // },
      {
        Header: "Source IP",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`SourceIP_${row?.original?.ID}`] ? (
              <>
 <p>{row?.original?.SourceIP}</p>
{row?.original?.SourceIP?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`SourceIP_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "SourceIP")}
                  >
                    {rowExpandState[`SourceIP_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.SourceIP?.length > 170
                    ? row?.original?.SourceIP?.slice(0, 170) + "..."
                    : row?.original?.SourceIP}
                </p>
                {row?.original?.SourceIP?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`SourceIP_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "SourceIP")}
                  >
                    {rowExpandState[`SourceIP_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Source Port",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`SourcePort_${row?.original?.ID}`] ? (
              <>
 <p>{row?.original?.SourcePort}</p>
{row?.original?.SourcePort?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`SourcePort_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "SourcePort")}
                  >
                    {rowExpandState[`SourcePort_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.SourcePort?.length > 170
                    ? row?.original?.SourcePort?.slice(0, 170) + "..."
                    : row?.original?.SourcePort}
                </p>
                {row?.original?.SourcePort?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`SourcePort_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "SourcePort")}
                  >
                    {rowExpandState[`SourcePort_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Destination IP",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`DestinationIP_${row?.original?.ID}`] ? (
              <>
 <p>{row?.original?.DestinationIP}</p>
{row?.original?.DestinationIP?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`DestinationIP_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "DestinationIP")}
                  >
                    {rowExpandState[`DestinationIP_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.DestinationIP?.length > 170
                    ? row?.original?.DestinationIP?.slice(0, 170) + "..."
                    : row?.original?.DestinationIP}
                </p>
                {row?.original?.DestinationIP?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`DestinationIP_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "DestinationIP")}
                  >
                    {rowExpandState[`DestinationIP_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Destination Port",
        Cell: ({ row }) => (
          <div>
            {rowExpandState[`DestinationPort_${row?.original?.ID}`] ? (
              <>
 <p>{row?.original?.DestinationPort}</p>
{row?.original?.DestinationPort?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`DestinationPort_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "DestinationPort")}
                  >
                    {rowExpandState[`DestinationPort_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </>
             
              
            ) : (
              <div>
                <p>
                  {row?.original?.DestinationPort?.length > 170
                    ? row?.original?.DestinationPort?.slice(0, 170) + "..."
                    : row?.original?.DestinationPort}
                </p>
                {row?.original?.DestinationPort?.length > 170 && (
                  <button
                  className={
                    rowExpandState[`DestinationPort_${row?.original?.ID}`]
                      ? "btn btn-primary mb-2"
                      : "btn btn-success mb-2"
                  }
                    onClick={() => handleReadMore(row?.original?.ID, "DestinationPort")}
                  >
                    {rowExpandState[`DestinationPort_${row?.original?.ID}`] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Analyst Name",
        accessor: "AnalystName",
      },
      {
        Header: "Security Event Timestamp",
        accessor: "DisplayTimestamp",
      },
      {
        Header: "Security Event Timestamp End",
        accessor: "DisplayTimestampEnd",
        Cell: ({ row }) => (
          <p>
            {row?.original?.DisplayTimestampEnd === "2006-01-01T00:00:00Z" || row?.original?.DisplayTimestampEnd === "2006-01-01 00:00:00" ||row?.original?.DisplayTimestampEnd === "2006-01-01T06:00:00"
              ? "N/A"
              : row?.original?.DisplayTimestampEnd}
          </p>
        ),
      },
      {
        Header: "Analyst Actions",
        Cell: ({ row }) => (
          <div>
            <button
              className="btn btn-primary mb-2"
              onClick={() => handleEdit(row?.original)}
            >
              Edit
            </button>
            <br></br>
            {(localStorage.getItem("isAdministrator") ||
              localStorage.getItem("isManager")|| localStorage.getItem("isAnalyst") )&& (
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(row?.original)}
              >
                Delete
              </button>
            )}

            {/* <button className={grouped ? 'btn btn-disabled' : 'btn btn-primary mb-2'} disabled={grouped} onClick={() => handleEdit(row.original)}>Edit</button><br></br>
              <button className={grouped ? 'btn btn-disabled' : 'btn btn-danger'} disabled={grouped} onClick={() => handleDelete(row.original)}>Delete</button> */}
          </div>
        ),
      },
      {
        Header: "Event Severity",
        accessor: "EventSeverity",

        Cell: ({ row }) => (
          <div>
            {row?.original?.EventSeverity === 1 && (
              <div>
                <img style={{ width: 24, height: 24 }} src={red} />
              </div>
            )}

            {row?.original?.EventSeverity === 2 && (
              <div>
                {" "}
                <img style={{ width: 24, height: 24 }} src={orange} />
              </div>
            )}

            {row?.original?.EventSeverity === 3 && (
              <div>
                <img style={{ width: 24, height: 24 }} src={yellow} />
              </div>
            )}
          </div>
        ),
      },

      {
        Header: "Resolved",
        accessor: "Resolved",
        Cell: ({ row }) => (
          <div>
            {row?.original?.Resolved === 1 && (
              <div>
                <img
                  onClick={() => handleEditEvent(row?.original)}
                  style={{ width: 24, height: 24 }}
                  src={crossRed}
                />
              </div>
            )}

            {row?.original?.Resolved === 2 && (
              <div>
                <img style={{ width: 24, height: 24 }} src={checked} />
              </div>
            )}

            {/* { showMore?
                  <div><button type='button' style={{backgroundColor:"black", color:"white"}} onClick={handleReadMore}>Read Less</button></div>
                  :
                  <div><button type='button' style={{backgroundColor:"black", color:"white"}} onClick={handleReadMore}>Read More</button></div>
                } */}
          </div>
        ),
      },
      {
        Header: "Resolve Comments",
        accessor: "Comments",
      }
      // {
      //   Header: 'View More',

      //   Cell: ({ row }) => (
      //     <div>
      //       <button className={rowExpandState[row.id]?'btn btn-success mb-2':'btn btn-primary mb-2'} onClick={() => handleReadMore(row.original.ID)}>
      //               {rowExpandState[row.id] ? 'Read Less' : 'Read More'}
      //             </button>
      //     </div>

      //   ),
      // },
    ],

    [rowExpandState, data]
  );

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);

    if (fetchId === fetchIdRef.current) {
      const token = window.localStorage.getItem("accessToken");

      if (token) {
        let timestamp = "";
        switch (timeFilter) {
          case "1h":
            timestamp = moment().subtract(1, "hours").toISOString();
            break;
          case "24h":
            timestamp = moment().subtract(1, "days").toISOString();
            break;
          case "72h":
            timestamp = moment().subtract(3, "days").toISOString();
            break;
          case "1w":
            timestamp = moment().subtract(1, "weeks").toISOString();
            break;
          case "1m":
            timestamp = moment().subtract(1, "months").toISOString();
            break;
          default:
            timestamp = "";
        }
        const url =
          `${
            process.env.REACT_APP_API_URL
          }/api/v1/authenticated/events?pageSize=${pageSize}&page=${
            pageIndex + 1
          }&grouped=${grouped}&customer=${customer}&event=${searchTerm}&analyst=${analyst}` +
          (timestamp ? `&createdAfter=${timestamp}` : "");
        await axios
          .get(url, {
            headers: {
              authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            setData(response?.data?.data || []); // Added null checks
            setPageCount(Math.ceil((response?.data?.total || 0) / pageSize));
          })
          .catch((error) => {
            console.log("Error fetching data:", error);
            // Add additional error handling here as needed
            setAlertMsg("Error fetching data. Please try again later.");
            setDisplayAlert("block");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  const fetchCustomers = async () => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);

    if (fetchId === fetchIdRef.current) {
      const token = window.localStorage.getItem("accessToken");

      if (token) {
        let url;
        if (localStorage.getItem("isAnalyst") === false) {
          url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/all-user-listing`;
        } else if (localStorage.getItem("isAdministrator") === true) {
          url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/all-user-listing`;
        } else {
          url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/all-user-listing`;
        }
        await axios
          .get(url, {
            headers: {
              authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            const customerArr = response?.data?.data?.filter((customer) => {
              return customer?.IsCustomer;
            });
            setAllCustomners(customerArr);
            const analystArr = response?.data?.data?.filter((analyst) => {
              return analyst?.IsAnalyst;
            });

            setAllAnalysts(analystArr);
            // Added null checks
            // setPageCount(Math.ceil((response?.data?.total || 0) / pageSize));
          })
          .catch((error) => {
            console.log("Error fetching data:", error);
            // Add additional error handling here as needed
            setAlertMsg("Error fetching data. Please try again later.");
            setDisplayAlert("block");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleEditEvent = (row) => {
    let rowCopy = row;
    delete row["CreatedAt"];
    delete row["UpdatedAt"];
    delete row["DeletedAt"];
    row["DisplayTimestamp"] = row["DisplayTimestamp"]
      .replace("Z", "")
      .replace("T", " ");
    row["DisplayTimestampEnd"] = row["DisplayTimestampEnd"]
      .replace("Z", "")
      .replace("T", " ");
    row["Resolved"] = 2;
    row["DestinationPort"] = row["DestinationPort"].toString();
    row["SourcePort"] = row["SourcePort"].toString();

    let token = window.localStorage.getItem("accessToken");
    let config = {
      method: "put",
      url:
        `${process.env.REACT_APP_API_URL}/api/v1/authenticated/events/` +
        row?.ID,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: row,
    };

    let newData = [...data];
    axios
      .request(config)
      .then((response) => {
        newData.forEach((element) => {
          if (element.id == row?.ID) {
            rowCopy["Resolved"] = 2;
            element = rowCopy;
          }
        });
        setData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = (row) => {
    // handle edit
    navigate(`/Analyst/Edit/${row?.ID}`);
  };

  const handleDelete = async (row) => {
    // Show a confirmation dialog
    const confirmation = window.confirm(
      "Are you sure you want to delete this record? This action cannot be undone!"
    );

    // If the user clicked "OK", proceed with the deletion
    if (confirmation) {
      // Get the access token
      const token = window.localStorage.getItem("accessToken");

      // Check if token exists
      if (token) {
        // Send a DELETE request to your API
        await axios
          .delete(
            `${process.env.REACT_APP_API_URL}/api/v1/authenticated/events/` +
              row?.ID,
            {
              headers: {
                authorization: "Bearer " + token,
              },
            }
          )
          .then((response) => {
            // Successfully deleted
            // You may want to fetch data again here to ensure your table is up-to-date
            fetchData({ pageIndex: 0, pageSize });
          })
          .catch((error) => {
            // Failed to delete
            console.log("Delete Error:", error);
            // Add additional error handling here as needed
            setAlertMsg(
              "Only the analyst who created the alert can delete it. Please contact the analyst who created the alert to delete it."
            );
            setDisplayAlert("block");
          });
      } else {
        console.log("No Access Token Found");
      }
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize: statePageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: pageCount,
    },
    usePagination
  );

  // useEffect(() => {
  //   fetchData({ pageIndex, pageSize });

  // }, [customer, searchTerm, analyst, pageIndex, pageSize, timeFilter, grouped]);
  useEffect(() => {
    fetchData({ pageIndex, pageSize });
    fetchCustomers();
  }, [
    customer,
    searchTerm,
    analyst,
    pageIndex,
    pageSize,
    timeFilter,
    grouped,
    data,
  ]);

  return (
    <>
      <>
        <Alert
          type={alertType}
          message={alertMsg}
          show={displayAlert}
          close={() => setDisplayAlert("none")}
        />

        <div
          className={
            data?.length > 0
              ? "top-header-new top-header header-height"
              : "top-header header-height"
          }
        >
          <section class="analyst-dahboard">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-12 pt-0 pt-lg-3 pb-2 position-relative">
                  <h1>
                    <span class="welcome-head">Welcome to the</span>
                    <br></br>
                    <span class="welcome-head-left">Decept 10</span>
                    <span class="welcome-head-right">
                      SOC Analyst Dashboard
                    </span>
                  </h1>
                  {/* <Switch
                    checked={grouped}
                    onChange={() => setGrouped(!grouped)}
                    checkedChildren="Grouped Alerts"
                    unCheckedChildren="Ungrouped Alerts"
                    className='switch-toggle'
                  /> */}
                </div>

                <div class="col-12 pb-3 pt-3 pt-sm-0">
                  <div class="main-filter d-flex gap-2 gap-md-3 gap-xl-5">
                    <div class="input-group search-input-head new-search-head">
                      <input
                        type="search"
                        class="form-control custom-drop drop-new"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search by Event Title"
                        aria-label="Search"
                        aria-describedby="search-addon"
                      />
                      <img src={searchIcon} class="search-input-icon" />
                    </div>

                    <div class="dropdown">
                      <Select
                        value={customer}
                        className="custom-drop"
                        onChange={(value) => setCustomer(value)}
                      >
                        <Option value="">All Customers</Option>
                        {/* <Option value="viperlineoffice">Viperline Office</Option>
                      <Option value="tcss">
                        Tuscaloosa County School System
                      </Option>
                      <Option value="mps">Montgomery Public Schools</Option>
                      <Option value="bhm">Birmingham City Schools</Option>
                      <Option value="sev">Sevierville</Option>
                      <Option value="lee">Lee University</Option>
                      <Option value="cle">Cleveland</Option>
                      <Option value="afc">AFC</Option>
                      <Option value="scac">SCAC</Option> */}
                        {allCustomers?.map((customer) => {
                          return (
                            <Option value={customer?.Name}>
                              {customer?.Name?.toUpperCase()}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
                <div class="col-12 pb-3">
                  <div class="main-filter d-flex gap-2 gap-md-3 gap-xl-5">
                    <div class="dropdown">
                      <Select
                        defaultValue=""
                        className="custom-drop"
                        onChange={(value) => setAnalyst(value)}
                      >
                        <Option value="">All Analysts</Option>
                        {allAnalysts.map((analyst) => {
                          return (
                            <Option value={analyst.Name}>{analyst.Name}</Option>
                          );
                        })}
                        {/* <Option value="amer">Amer</Option>
                      <Option value="brooke">Brooke</Option>
                      <Option value="caleb">Caleb</Option>
                      <Option value="ian">Ian</Option>
                      <Option value="mo">Mo</Option>
                      <Option value="nathaniel">Nathaniel</Option>
                      <Option value="deffala">Deffala</Option>
                      <Option value="nathaniel">Nate</Option> */}
                      </Select>
                    </div>
                    <div class="dropdown">
                      <Select
                        className="custom-drop"
                        placeholder="Select time period"
                        onChange={(value) => setTimeFilter(value)}
                        value={timeFilter}
                      >
                        {timeOptions.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="bottom-section pt-5">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive main-table">
                    <table {...getTableProps()} className="table">
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                              </th>
                            ))}
                            {/* <th>View More</th> */}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              ))}
                              {/* <td>
                    <button className={showMore?'btn btn-primary mb-2':'btn btn-success mb-2'} onClick={() => handleReadMore()}>
                      {showMore ? 'Read More' : 'Read Less'}
                    </button>
                  </td>  */}
                              {/* <td>
                                <button
                                  className={
                                    rowExpandState[row?.original?.ID]
                                      ? "btn btn-primary mb-2"
                                      : "btn btn-success mb-2"
                                  }
                                  onClick={() =>
                                    handleReadMore(row?.original?.ID)
                                  }
                                >
                                  {rowExpandState[row?.original?.ID]
                                    ? "Read Less"
                                    : "Read More"}
                                </button>
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="d-flex justify-content-center align-items-center mt-4 mb-5">
                      <div className="pagination-head">
                        <button
                          className="btn btn-outline-secondary me-2"
                          onClick={() => gotoPage(0)}
                          disabled={!canPreviousPage}
                        >
                          {"first"}
                        </button>
                        <button
                          className="me-3 p-0 border-0 bg-transparent me-3"
                          type="button"
                          onClick={previousPage}
                          disabled={!canPreviousPage}
                        >
                          <img src={arrowLeft} />
                        </button>
                        <span>Page {pageIndex + 1}</span>
                        <button
                          className="me-3 p-0 border-0 bg-transparent ms-3"
                          type="button"
                          onClick={nextPage}
                          disabled={!canNextPage}
                        >
                          <img src={arrowRight} />
                        </button>
                        <button
                          className="btn btn-outline-secondary me-2"
                          type="button"
                          onClick={() => gotoPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          {"last"}
                        </button>
                      </div>
                      {/* <div>
                        <span>
                          Page{' '}
                          <strong>
                            {pageIndex + 1} of {pageOptions.length}
                          </strong>{' '}
                        </span>
                        <span className="ms-2">
                          | Go to page:{' '}
                          <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                              const page = e.target.value ? Number(e.target.value) - 1 : 0;
                              gotoPage(page);
                            }}
                            style={{ width: '100px' }}
                            className="form-control mx-2 d-inline-block"
                          />
                        </span>
                        <select
                          value={statePageSize} // use statePageSize here
                          onChange={e => {
                            setPageSize(Number(e.target.value));
                            fetchData({ pageIndex, pageSize: Number(e.target.value) });
                          }}
                          className="form-select"
                        >
                          {[10, 20, 30, 40, 50].map(size => (
                            <option key={size} value={size}>
                              Show {size}
                            </option>
                          ))}
                        </select>
                      </div> */}
                    </div>
                  </div>
                  <div className="bookmark-icon">
                    <img
                      style={{ width: 24, height: 24 }}
                      src={bottomToTop}
                      onClick={() => window.scrollTo(0, 0)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {loading && <div>Loading...</div>}
      </>
    </>
  );
};

export default AlertsTable;
