import React, { useEffect, useState } from "react";
import axios from "axios";
import { Switch, Select, Input, Space } from "antd";
import { useTable, usePagination } from "react-table";
// import { useTable , usePagination} from 'react-table/dist/react-table.development';
import { useNavigate } from "react-router-dom";
import moment from "moment";
// import Alert from '../../partials/Alert';
import Alert from "./partials/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import {
  arrowLeft,
  arrowRight,
  bottomToTop,
  checked,
  crossIcon,
  crossRed,
  green,
  orange,
  red,
  searchIcon,
  yellow,
} from "./assets";
import { data } from "jquery";

const AlertsTable = () => {
  const [displayAlert, setDisplayAlert] = useState("none");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType] = useState("danger");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [customer, setCustomer] = useState("");
  const [analyst, setAnalyst] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [timeFilter, setTimeFilter] = useState("72h");
  let [grouped, setGrouped] = useState(false); // ungrouped by default
  const { Option } = Select;
  const { Search } = Input;
  const fetchIdRef = React.useRef(0);
  const pageSize = 20; // set the number of items per page
  const navigate = useNavigate(); // Instantiate useHistory hook
  const [read, setRead] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  // Time options
  const timeOptions = [
    { label: "Last hour", value: "1h" },
    { label: "Last day", value: "24h" },
    { label: "Last 3 days", value: "72h" },
    { label: "Last week", value: "1w" },
    { label: "Last month", value: "1m" },
  ];

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Email",
        // accessor: "Email",
        Cell: ({ row }) => (
          <div>
            <p>
              {[
                row?.original?.Email,
                row?.original?.Email2,
                row?.original?.Email3,
                row?.original?.Email4,
                row?.original?.Email5,
                row?.original?.Email6,
                row?.original?.Email7,
                row?.original?.Email8,
                row?.original?.Email9,
                row?.original?.Email10,
              ]
                .filter((email) => email !== "") // Filter out empty strings
                .join(", ")}
            </p>
          </div>
        ),
      },
      {
        Header: "Account Type",
        Cell: ({ row }) => (
          <div>
            <p>
              {row?.original?.IsAdministrator
                ? "Administrator"
                : row?.original?.IsManager
                ? "Manager"
                : row?.original?.IsAnalyst
                ? "Analyst"
                : "Customer"}
            </p>
          </div>
        ),
      },
      {
        Header: "Is Active",
        // accessor: 'IsAccountActivated',
        Cell: ({ row }) => (
          <div>
            {row?.original?.IsAccountActivated ? (
              <button
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#0fcc45",
                  height: "20px",
                  width: "20px",
                  border: "none",
                }}
              ></button>
            ) : (
              <button
                style={{
                  borderRadius: "50%",
                  backgroundColor: "red",
                  height: "20px",
                  width: "20px",
                  border: "none",
                }}
              ></button>
            )}
          </div>
        ),
      },
      {
        Header: "Analyst Actions",
        Cell: ({ row }) => (
          <div className="d-flex justify-content-evenly">
            <button
              className="btn btn-primary mb-2"
              onClick={(e) => handleEdit(row?.original)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <br></br>
            <button
              className="btn btn-danger"
              onClick={() => handleDelete(row?.original)}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>

            {/* <button className={grouped ? 'btn btn-disabled' : 'btn btn-primary mb-2'} disabled={grouped} onClick={() => handleEdit(row.original)}>Edit</button><br></br>
                    <button className={grouped ? 'btn btn-disabled' : 'btn btn-danger'} disabled={grouped} onClick={() => handleDelete(row.original)}>Delete</button> */}
          </div>
        ),
      },
    ],
    []
  );

  const fetchData = async () => {
    const fetchId = ++fetchIdRef.current;

    setLoading(true);

    if (fetchId === fetchIdRef.current) {
      const token = window.localStorage.getItem("accessToken");

      if (token) {
        let url;
        if (localStorage.getItem("isAnalyst") === false) {
          url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/all-user-listing`;
        } else if (localStorage.getItem("isAdministrator") === true) {
          url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/all-user-listing`;
        } else {
          url = `${process.env.REACT_APP_API_URL}/api/v1/authenticated/all-user-listing`;
        }
        await axios
          .get(url, {
            headers: {
              authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            setData(response?.data?.data || []); // Added null checks
            // setPageCount(Math.ceil((response?.data?.total || 0) / pageSize));
          })
          .catch((error) => {
            console.log("Error fetching data:", error);
            // Add additional error handling here as needed
            setAlertMsg("Error fetching data. Please try again later.");
            setDisplayAlert("block");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleEditEvent = (row) => {
    let rowCopy = row;
    delete row["CreatedAt"];
    delete row["UpdatedAt"];
    delete row["DeletedAt"];
    row["DisplayTimestamp"] = row["DisplayTimestamp"]
      .replace("Z", "")
      .replace("T", " ");
    row["DisplayTimestampEnd"] = row["DisplayTimestampEnd"]
      .replace("Z", "")
      .replace("T", " ");
    row["Resolved"] = 2;
    row["DestinationPort"] = row["DestinationPort"].toString();
    row["SourcePort"] = row["SourcePort"].toString();

    let token = window.localStorage.getItem("accessToken");
    let config = {
      method: "put",
      url:
        `${process.env.REACT_APP_API_URL}/api/v1/authenticated/events/` +
        row?.ID,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: row,
    };

    let newData = [...data];
    axios
      .request(config)
      .then((response) => {
        newData.forEach((element) => {
          if (element.id == row?.ID) {
            rowCopy["Resolved"] = 2;
            element = rowCopy;
          }
        });
        setData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = (details) => {
    // handle edit
    navigate(`/Manager/edit-user`, { state: { userDetails: details } });
  };

  const handleDelete = async (row) => {
    // Show a confirmation dialog
    const confirmation = window.confirm(
      "Are you sure you want to delete this record? This action cannot be undone!"
    );

    // If the user clicked "OK", proceed with the deletion
    if (confirmation) {
      // Get the access token
      const token = window.localStorage.getItem("accessToken");

      // Check if token exists
      if (token) {
        // Send a DELETE request to your API
        await axios
          .delete(
            isAdmin
              ? `${process.env.REACT_APP_API_URL}/api/v1/authenticated/admin/user-delete/`
              : `${process.env.REACT_APP_API_URL}/api/v1/authenticated/user-delete/` +
                  row?.ID,
            {
              headers: {
                authorization: "Bearer " + token,
              },
            }
          )
          .then((response) => {
            // Successfully deleted
            // You may want to fetch data again here to ensure your table is up-to-date
            fetchData();
          })
          .catch((error) => {
            // Failed to delete
            console.log("Delete Error:", error);
            // Add additional error handling here as needed
            // setAlertMsg("Only the analyst who created the alert can delete it. Please contact the analyst who created the alert to delete it.");
            setDisplayAlert("block");
          });
      } else {
        console.log("No Access Token Found");
      }
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize: statePageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: pageCount,
    },
    usePagination
  );

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);
  }, [customer, searchTerm, analyst, pageIndex, pageSize, timeFilter, grouped]);
  useEffect(() => {
    try {
      const token = window.localStorage.getItem("accessToken");

      axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/authenticated/me`, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          setIsAdmin(response?.data?.IsAdministrator);
        })
        .catch(function (err) {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Alert
        type={alertType}
        message={alertMsg}
        show={displayAlert}
        close={() => setDisplayAlert("none")}
      />

      <div
        className={
          data?.length > 0
            ? "top-header-new top-header header-height"
            : "top-header header-height"
        }
      >
        <section class="bottom-section pt-5">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive main-table">
                  <table {...getTableProps()} className="table">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center align-items-center mt-4 mb-5">
                    <div className="pagination-head">
                      <button
                        className="btn btn-outline-secondary me-2"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        {"first"}
                      </button>
                      <button
                        className="me-3 p-0 border-0 bg-transparent me-3"
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                      >
                        <img src={arrowLeft} />
                      </button>
                      <span>Page {pageIndex + 1}</span>
                      <button
                        className="me-3 p-0 border-0 bg-transparent ms-3"
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        <img src={arrowRight} />
                      </button>
                      <button
                        className="btn btn-outline-secondary me-2"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        {"last"}
                      </button>
                    </div>
                    {/* <div>
                      <span>
                        Page{' '}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                      </span>
                      <span className="ms-2">
                        | Go to page:{' '}
                        <input
                          type="number"
                          defaultValue={pageIndex + 1}
                          onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                          }}
                          style={{ width: '100px' }}
                          className="form-control mx-2 d-inline-block"
                        />
                      </span>
                      <select
                        value={statePageSize} // use statePageSize here
                        onChange={e => {
                          setPageSize(Number(e.target.value));
                          fetchData({ pageIndex, pageSize: Number(e.target.value) });
                        }}
                        className="form-select"
                      >
                        {[10, 20, 30, 40, 50].map(size => (
                          <option key={size} value={size}>
                            Show {size}
                          </option>
                        ))}
                      </select>
                    </div> */}
                  </div>
                </div>
                <div className="bookmark-icon">
                  <img
                    style={{ width: 24, height: 24 }}
                    src={bottomToTop}
                    onClick={() => window.scrollTo(0, 0)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {loading && <div>Loading...</div>}
    </>
  );
};

export default AlertsTable;
