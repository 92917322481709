import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "../../partials/Alert";
import axios from "axios";

const CompletePasswordlessLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [displayAlert, setDisplayAlert] = useState("none");
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType] = useState("danger");

  useEffect(() => {
    const data = location.hash.substring(1); // remove #
    const decodedData = decodeURIComponent(data);
    const [email, loginCode] = decodedData.split("::");

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/account/login`, {
        email: email,
        loginCode: loginCode,
      })
      .then(function (response) {
        navigate("/");
        const token = response.data.replace(/"/g, "");
        window.localStorage.setItem("accessToken", token);
      })
      .catch((err) => {
        setDisplayAlert("block");
        setAlertMsg(
          "We were unable to verify your identity. Please request a new code and try again! If the problem persists, please contact support."
        );
      });
  }, [location, navigate]);

  return (
    <>
      <Alert
        type={alertType}
        message={alertMsg}
        show={displayAlert}
        close={() => {
          setDisplayAlert("none");
          location.reload();
        }}
      />
    </>
  );
};

export default CompletePasswordlessLogin;
