import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlertsTable from "./Analyst/AlertTable";
import CustomerIndex from "./Customer/CustomerIndex";

const Home = () => {
  const navigate = useNavigate(); // Instantiate useHistory hook
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isAnalyst, setIsAnalyst] = useState(false);
  const [hasReportingAccess, setHasReportingAccess] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    try {
      const token = window.localStorage.getItem("accessToken");
      if (token) {
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/v1/authenticated/me`, {
            headers: {
              authorization: "Bearer " + token,
            },
          })
          .then(function (response) {
            setIsAuthenticated(true);
            setIsCustomer(response.data.IsCustomer);
            setIsAnalyst(response.data.IsAnalyst);
            setHasReportingAccess(response.data.HasReportingAccess);
            setIsManager(response.data.IsManager);
            setIsAdmin(response.data.IsAdministrator);
          });
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    navigate,
    isCustomer,
    isCustomer,
    isAnalyst,
    hasReportingAccess,
    isManager,
    isAdmin,
  ]);

  if (isAnalyst || isAdmin||isManager) {
  return navigate("/Analyst/Index");
  }
  else if (isCustomer) {
    return navigate('/Customer/Index');
  }
  //  else if (isManager) {
  //   return navigate('/Customer/Index');
  // }
};

export default Home;
